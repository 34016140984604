import { FC, PropsWithChildren } from 'react';
import { Link as RLink } from '@radix-ui/react-navigation-menu';

const NavLinkWrapper: FC<PropsWithChildren> = (props) => {
  return (
    <RLink {...props} asChild>
      {props.children}
    </RLink>
  );
};

export default NavLinkWrapper;
