import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { pipe } from 'fp-ts/lib/function';
import { chain, fromPredicate, match } from 'fp-ts/lib/Option';
import { tapO } from '@oresundsbron/utilities';

const toVoid = match(
  () => {},
  () => {}
);

export const reactPlugin = new ReactPlugin();

const defaultBrowserHistory = {
  url: '/',
  location: { pathname: '' },
  listen: () => {},
};

let browserHistory = defaultBrowserHistory;

if (typeof window !== 'undefined') {
  browserHistory = { ...browserHistory, ...window.history };
  browserHistory.location.pathname = (browserHistory as any)?.state?.url;
}

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.APPINSIGHTS_INSTRUMENTATIONKEY,
    maxBatchSizeInBytes: 10000,
    maxBatchInterval: 15000,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: browserHistory,
    },
  },
});

export const initialize = () =>
  pipe(
    appInsights.core.isInitialized && !appInsights.core.isInitialized(),
    fromPredicate((x) => !!x),
    chain(
      fromPredicate(
        () => process.env.NODE_ENV === 'production' && process.env.CI !== 'true'
      )
    ),
    tapO(() => appInsights.loadAppInsights()),
    toVoid
  );
