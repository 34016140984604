import { jsx as jsx$1 } from '@emotion/react';
import { p as parseStylingProps } from './jsx-props-03489908.esm.js';
export { c as css, b as excludeCssProps, d as excludeProp, f as excludeProps, e as excludeSystemProps, a as extractSystemProps } from './jsx-props-03489908.esm.js';
import 'fp-ts/Option';
import 'fp-ts/function';
import 'fp-ts/Record';
import 'fp-ts/Array';
import 'fp-ts/boolean';

var createTheme = function createTheme(tokens, components, utils) {
  return {
    tokens: tokens,
    components: components,
    utils: utils
  };
};

var jsx = function jsx(type, props) {
  for (var _len = arguments.length, children = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
    children[_key - 2] = arguments[_key];
  }

  return jsx$1.apply(void 0, [type, parseStylingProps(props)].concat(children));
};
/**
 * @internal for Babel JSX pragma
 */

var createElement = jsx;

export { createElement, createTheme, jsx };
