import { Asset } from '@oresundsbron/api';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';

import Image from 'next/image';
import { FC } from 'react';

export const AssetImage: FC<Asset> = ({ url, description }) => (
  <AspectRatio.Root ratio={16 / 9} className="my-4">
    <Image
      alt={description || ''}
      src={url}
      sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
      fill
      style={{
        aspectRatio: '16/9',
        objectFit: 'cover',
      }}
    />
  </AspectRatio.Root>
);
