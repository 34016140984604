import { overrideTheme } from '@oresundsbron/design-system';

export const theme = overrideTheme({
  colorModes: {
    club: {
      primary: {
        50: '#F3F0FA',
        100: '#EAE5F5',
        200: '#D5CBEC',
        300: '#BCADE1',
        400: '#A793D7',
        500: '#9279CE',
        600: '#7D5EC4', //Ultraviolet
        700: '#6240AF',
        800: '#4D338A',
        900: '#3A2669',
        950: '#271A47',
      },
    },
  },
});
