import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC, PropsWithChildren } from 'react';
import { reactPlugin } from '../lib/appInsight';

const AzureAppInsights: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};

export default AzureAppInsights;
