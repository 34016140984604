import { Block, Inline } from '@contentful/rich-text-types';
import { Sys } from '@oresundsbron/api';
import { findFirst } from 'fp-ts/lib/Array';
import { pipe } from 'fp-ts/lib/function';
import { chain, getOrElseW, map } from 'fp-ts/lib/Option';
import { lookup } from 'fp-ts/lib/Record';
import { FC, ReactNode } from 'react';
import { ButtonLink } from './ButtonLink';
import { PriceList } from './PriceList';
import { ProductCardGroup } from './ProductCardGroup';
import { ClubList } from './ClubList';

const EntryMap: Record<string, FC<any>> = {
  ButtonLink: ButtonLink,
  ProductCardGroup: ProductCardGroup,
  PriceList: PriceList,
  ClubList: ClubList,
};

export const EmbeddedEntry =
  (entries: { sys: Sys; __typename: string }[]) =>
  (node: Block | Inline, children: ReactNode) =>
    pipe(
      entries,
      findFirst((e) => e.sys?.id === node.data.target.sys.id),
      chain((e) =>
        pipe(
          EntryMap,
          lookup(e.__typename),
          map((Comp) => <Comp {...e}>{children}</Comp>)
        )
      ),
      getOrElseW(() => null)
    );
