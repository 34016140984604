/*
Vehicle classes
+------+----------------------------+
| code | title                      |
+------+----------------------------+
| 1    | Motorcycle                 |
| 2    | Car up to 6 metres         |
| 3    | Vehicle 6-10 metres        |
| 4    | Bus over 10 metres         |
| 5    | Lorry 9-20 meter           |
| 6    | Lorry > 20 meter           |
| 9    | Special transport          |
| 10   | Escort                     |
+------+----------------------------+
*/

import { priceGroupParser } from '@oresundsbron/validators';
import { z } from 'zod';

export type TranslationKey =
  | 'car'
  | 'car_with_trailer_max_15'
  | 'car_with_trailer_over_15'
  | 'camper'
  | 'camper_max_10'
  | 'camper_over_10'
  | 'camper_with_trailer'
  | 'minibus'
  | 'van'
  | 'van_over_9'
  | 'truck'
  | 'bus'
  | 'mc';

export type Vehicle = {
  label: string;
  classID: '1' | '2' | '3' | '4' | '5' | '6' | '9' | '10';
};

export const VehicleMap: Record<string, Vehicle[]> = {
  car: [
    { classID: '2', label: 'vehicles.car' },
    { classID: '3', label: 'vehicles.car_with_trailer_max_15' },
    { classID: '5', label: 'vehicles.car_with_trailer_over_15' },
  ],
  camper: [
    { classID: '2', label: 'vehicles.camper' },
    { classID: '3', label: 'vehicles.camper_max_10' },
    { classID: '5', label: 'vehicles.camper_over_10' },
    { classID: '5', label: 'vehicles.camper_with_trailer' },
  ],
  minibus: [{ classID: '3', label: 'vehicles.minibus' }],
  van: [
    { classID: '3', label: 'vehicles.van' },
    { classID: '5', label: 'vehicles.van_over_9' },
  ],
  truck: [{ classID: '6', label: 'vehicles.truck' }],
  bus: [{ classID: '4', label: 'vehicles.bus' }],
  mc: [{ classID: '1', label: 'vehicles.mc' }],
};

export const Vehicles: Vehicle[] = [
  ...VehicleMap.car,
  ...VehicleMap.camper,
  ...VehicleMap.minibus,
  ...VehicleMap.van,
  ...VehicleMap.truck,
  ...VehicleMap.bus,
  ...VehicleMap.mc,
];

export type Prices = z.infer<typeof priceGroupParser>;

export const Fullprice = [
  {
    classId: 1,
    currency: 'DKK',
    priceInclVat: 225,
    priceExclVat: 180,
    salesPriceInclVat: 215,
    salesPriceExclVat: 172,
  },
  {
    classId: 1,
    currency: 'SEK',
    priceInclVat: 355,
    priceExclVat: 284,
    salesPriceInclVat: 335,
    salesPriceExclVat: 268,
  },
  {
    classId: 1,
    currency: 'EUR',
    priceInclVat: 35,
    priceExclVat: 28,
    salesPriceInclVat: 34,
    salesPriceExclVat: 27.2,
  },
  {
    classId: 2,
    currency: 'DKK',
    priceInclVat: 440,
    priceExclVat: 352,
    salesPriceInclVat: 420,
    salesPriceExclVat: 336,
  },
  {
    classId: 2,
    currency: 'SEK',
    priceInclVat: 695,
    priceExclVat: 556,
    salesPriceInclVat: 665,
    salesPriceExclVat: 532,
  },
  {
    classId: 2,
    currency: 'EUR',
    priceInclVat: 67,
    priceExclVat: 53.6,
    salesPriceInclVat: 64,
    salesPriceExclVat: 51.2,
  },
  {
    classId: 3,
    currency: 'DKK',
    priceInclVat: 880,
    priceExclVat: 704,
    salesPriceInclVat: 840,
    salesPriceExclVat: 672,
  },
  {
    classId: 3,
    currency: 'SEK',
    priceInclVat: 1390,
    priceExclVat: 1112,
    salesPriceInclVat: 1330,
    salesPriceExclVat: 1064,
  },
  {
    classId: 3,
    currency: 'EUR',
    priceInclVat: 134,
    priceExclVat: 107.2,
    salesPriceInclVat: 128,
    salesPriceExclVat: 102.4,
  },
  {
    classId: 4,
    currency: 'DKK',
    priceInclVat: 2015,
    priceExclVat: 1612,
    salesPriceInclVat: 1915,
    salesPriceExclVat: 1532,
  },
  {
    classId: 4,
    currency: 'SEK',
    priceInclVat: 3200,
    priceExclVat: 2560,
    salesPriceInclVat: 3040,
    salesPriceExclVat: 2432,
  },
  {
    classId: 4,
    currency: 'EUR',
    priceInclVat: 277,
    priceExclVat: 221.6,
    salesPriceInclVat: 263,
    salesPriceExclVat: 210.4,
  },
  {
    classId: 5,
    currency: 'DKK',
    priceInclVat: 1570,
    priceExclVat: 1256,
    salesPriceInclVat: 1495,
    salesPriceExclVat: 1196,
  },
  {
    classId: 5,
    currency: 'SEK',
    priceInclVat: 2490,
    priceExclVat: 1992,
    salesPriceInclVat: 2365,
    salesPriceExclVat: 1892,
  },
  {
    classId: 5,
    currency: 'EUR',
    priceInclVat: 216,
    priceExclVat: 172.8,
    salesPriceInclVat: 206,
    salesPriceExclVat: 164.8,
  },
  {
    classId: 6,
    currency: 'DKK',
    priceInclVat: 1570,
    priceExclVat: 1256,
    salesPriceInclVat: 1495,
    salesPriceExclVat: 1196,
  },
  {
    classId: 6,
    currency: 'SEK',
    priceInclVat: 2490,
    priceExclVat: 1992,
    salesPriceInclVat: 2365,
    salesPriceExclVat: 1892,
  },
  {
    classId: 6,
    currency: 'EUR',
    priceInclVat: 216,
    priceExclVat: 172.8,
    salesPriceInclVat: 206,
    salesPriceExclVat: 164.8,
  },
];

export const vehiclePrices: Record<string, Prices[]> = {
  OresundGO: [
    {
      classId: 1,
      currency: 'DKK',
      salesPriceInclVat: 88,
      salesPriceExclVat: 70.4,
    },
    {
      classId: 1,
      currency: 'SEK',
      salesPriceInclVat: 140,
      salesPriceExclVat: 112,
    },
    {
      classId: 1,
      currency: 'EUR',
      salesPriceInclVat: 12,
      salesPriceExclVat: 9.6,
    },
    {
      classId: 10,
      currency: 'DKK',
      salesPriceInclVat: 3380,
      salesPriceExclVat: 2704,
    },
    {
      classId: 10,
      currency: 'SEK',
      salesPriceInclVat: 5365,
      salesPriceExclVat: 4292,
    },
    {
      classId: 10,
      currency: 'EUR',
      salesPriceInclVat: 465,
      salesPriceExclVat: 372,
    },
    {
      classId: 2,
      currency: 'DKK',
      salesPriceInclVat: 170,
      salesPriceExclVat: 136,
    },
    {
      classId: 2,
      currency: 'SEK',
      salesPriceInclVat: 270,
      salesPriceExclVat: 216,
    },
    {
      classId: 2,
      currency: 'EUR',
      salesPriceInclVat: 23,
      salesPriceExclVat: 18.4,
    },
    {
      classId: 3,
      currency: 'DKK',
      salesPriceInclVat: 340,
      salesPriceExclVat: 272,
    },
    {
      classId: 3,
      currency: 'SEK',
      salesPriceInclVat: 540,
      salesPriceExclVat: 432,
    },
    {
      classId: 3,
      currency: 'EUR',
      salesPriceInclVat: 46,
      salesPriceExclVat: 36.8,
    },
    {
      classId: 4,
      currency: 'DKK',
      salesPriceInclVat: 687.5,
      salesPriceExclVat: 550,
    },
    {
      classId: 4,
      currency: 'SEK',
      salesPriceInclVat: 1090,
      salesPriceExclVat: 872,
    },
    {
      classId: 4,
      currency: 'EUR',
      salesPriceInclVat: 92.9,
      salesPriceExclVat: 74.3,
    },
    {
      classId: 5,
      currency: 'DKK',
      salesPriceInclVat: 687.5,
      salesPriceExclVat: 550,
    },
    {
      classId: 5,
      currency: 'SEK',
      salesPriceInclVat: 1090,
      salesPriceExclVat: 872,
    },
    {
      classId: 5,
      currency: 'EUR',
      salesPriceInclVat: 92.9,
      salesPriceExclVat: 74.3,
    },
    {
      classId: 6,
      currency: 'DKK',
      salesPriceInclVat: 687.5,
      salesPriceExclVat: 550,
    },
    {
      classId: 6,
      currency: 'SEK',
      salesPriceInclVat: 1090,
      salesPriceExclVat: 872,
    },
    {
      classId: 6,
      currency: 'EUR',
      salesPriceInclVat: 92.9,
      salesPriceExclVat: 74.3,
    },
    {
      classId: 9,
      currency: 'DKK',
      salesPriceInclVat: 3140,
      salesPriceExclVat: 2512,
    },
    {
      classId: 9,
      currency: 'SEK',
      salesPriceInclVat: 4980,
      salesPriceExclVat: 3984,
    },
    {
      classId: 9,
      currency: 'EUR',
      salesPriceInclVat: 432,
      salesPriceExclVat: 345.6,
    },
  ],
  Oresund_Business: [
    {
      classId: 1,
      currency: 'DKK',
      salesPriceInclVat: 72,
      salesPriceExclVat: 57.6,
    },
    {
      classId: 1,
      currency: 'SEK',
      salesPriceInclVat: 105.6,
      salesPriceExclVat: 84.48,
    },
    {
      classId: 1,
      currency: 'EUR',
      salesPriceInclVat: 0,
      salesPriceExclVat: 0,
    },
    {
      classId: 10,
      currency: 'DKK',
      salesPriceInclVat: 0,
      salesPriceExclVat: 0,
    },
    {
      classId: 10,
      currency: 'SEK',
      salesPriceInclVat: 0,
      salesPriceExclVat: 0,
    },
    {
      classId: 10,
      currency: 'EUR',
      salesPriceInclVat: 0,
      salesPriceExclVat: 0,
    },
    {
      classId: 2,
      currency: 'DKK',
      salesPriceInclVat: 170,
      salesPriceExclVat: 136,
      fromQty: 1,
      toQty: 1000,
    },
    {
      classId: 2,
      currency: 'DKK',
      salesPriceInclVat: 165,
      salesPriceExclVat: 132,
      fromQty: 1001,
      toQty: 10000,
    },
    {
      classId: 2,
      currency: 'DKK',
      salesPriceInclVat: 160,
      salesPriceExclVat: 128,
      fromQty: 10001,
      toQty: 0,
    },
    {
      classId: 2,
      currency: 'SEK',
      salesPriceInclVat: 270,
      salesPriceExclVat: 216,
      fromQty: 1,
      toQty: 1000,
    },
    {
      classId: 2,
      currency: 'SEK',
      salesPriceInclVat: 262.5,
      salesPriceExclVat: 210,
      fromQty: 1001,
      toQty: 10000,
    },
    {
      classId: 2,
      currency: 'SEK',
      salesPriceInclVat: 253.75,
      salesPriceExclVat: 203,
      fromQty: 10001,
      toQty: 0,
    },
    {
      classId: 2,
      currency: 'EUR',
      salesPriceInclVat: 23,
      salesPriceExclVat: 18.4,
      fromQty: 1,
      toQty: 1000,
    },
    {
      classId: 2,
      currency: 'EUR',
      salesPriceInclVat: 22.3,
      salesPriceExclVat: 17.8,
      fromQty: 1001,
      toQty: 10000,
    },
    {
      classId: 2,
      currency: 'EUR',
      salesPriceInclVat: 21.6,
      salesPriceExclVat: 17.3,
      fromQty: 10001,
      toQty: 0,
    },
    {
      classId: 3,
      currency: 'DKK',
      salesPriceInclVat: 340,
      salesPriceExclVat: 272,
      fromQty: 1,
      toQty: 1000,
    },
    {
      classId: 3,
      currency: 'DKK',
      salesPriceInclVat: 330,
      salesPriceExclVat: 264,
      fromQty: 1001,
      toQty: 10000,
    },
    {
      classId: 3,
      currency: 'DKK',
      salesPriceInclVat: 320,
      salesPriceExclVat: 256,
      fromQty: 10001,
      toQty: 0,
    },
    {
      classId: 3,
      currency: 'SEK',
      salesPriceInclVat: 540,
      salesPriceExclVat: 432,
      fromQty: 1,
      toQty: 1000,
    },
    {
      classId: 3,
      currency: 'SEK',
      salesPriceInclVat: 525,
      salesPriceExclVat: 420,
      fromQty: 1001,
      toQty: 10000,
    },
    {
      classId: 3,
      currency: 'SEK',
      salesPriceInclVat: 507.5,
      salesPriceExclVat: 406,
      fromQty: 10001,
      toQty: 0,
    },
    {
      classId: 3,
      currency: 'EUR',
      salesPriceInclVat: 46,
      salesPriceExclVat: 36.8,
      fromQty: 1,
      toQty: 1000,
    },
    {
      classId: 3,
      currency: 'EUR',
      salesPriceInclVat: 44.6,
      salesPriceExclVat: 35.7,
      fromQty: 1001,
      toQty: 10000,
    },
    {
      classId: 3,
      currency: 'EUR',
      salesPriceInclVat: 43.3,
      salesPriceExclVat: 34.6,
      fromQty: 10001,
      toQty: 0,
    },
    {
      classId: 4,
      currency: 'DKK',
      salesPriceInclVat: 1508.75,
      salesPriceExclVat: 1207,
      fromQty: 1,
      toQty: 100,
    },
    {
      classId: 4,
      currency: 'DKK',
      salesPriceInclVat: 1445,
      salesPriceExclVat: 1156,
      fromQty: 101,
      toQty: 500,
    },
    {
      classId: 4,
      currency: 'DKK',
      salesPriceInclVat: 1397.5,
      salesPriceExclVat: 1118,
      fromQty: 501,
      toQty: 2000,
    },
    {
      classId: 4,
      currency: 'DKK',
      salesPriceInclVat: 1313.75,
      salesPriceExclVat: 1051,
      fromQty: 2001,
      toQty: 4000,
    },
    {
      classId: 4,
      currency: 'DKK',
      salesPriceInclVat: 1237.5,
      salesPriceExclVat: 990,
      fromQty: 4001,
      toQty: 0,
    },
    {
      classId: 4,
      currency: 'SEK',
      salesPriceInclVat: 2395,
      salesPriceExclVat: 1916,
      fromQty: 1,
      toQty: 100,
    },
    {
      classId: 4,
      currency: 'SEK',
      salesPriceInclVat: 2293.75,
      salesPriceExclVat: 1835,
      fromQty: 101,
      toQty: 500,
    },
    {
      classId: 4,
      currency: 'SEK',
      salesPriceInclVat: 2218.75,
      salesPriceExclVat: 1775,
      fromQty: 501,
      toQty: 2000,
    },
    {
      classId: 4,
      currency: 'SEK',
      salesPriceInclVat: 2085,
      salesPriceExclVat: 1668,
      fromQty: 2001,
      toQty: 4000,
    },
    {
      classId: 4,
      currency: 'SEK',
      salesPriceInclVat: 1963.75,
      salesPriceExclVat: 1571,
      fromQty: 4001,
      toQty: 0,
    },
    {
      classId: 4,
      currency: 'EUR',
      salesPriceInclVat: 203.9,
      salesPriceExclVat: 163.1,
      fromQty: 1,
      toQty: 100,
    },
    {
      classId: 4,
      currency: 'EUR',
      salesPriceInclVat: 195.3,
      salesPriceExclVat: 156.2,
      fromQty: 101,
      toQty: 500,
    },
    {
      classId: 4,
      currency: 'EUR',
      salesPriceInclVat: 188.9,
      salesPriceExclVat: 151.1,
      fromQty: 501,
      toQty: 2000,
    },
    {
      classId: 4,
      currency: 'EUR',
      salesPriceInclVat: 177.5,
      salesPriceExclVat: 142,
      fromQty: 2001,
      toQty: 4000,
    },
    {
      classId: 4,
      currency: 'EUR',
      salesPriceInclVat: 167.3,
      salesPriceExclVat: 133.8,
      fromQty: 4001,
      toQty: 0,
    },
    {
      classId: 5,
      currency: 'DKK',
      salesPriceInclVat: 687.5,
      salesPriceExclVat: 550,
      fromQty: 1,
      toQty: 500,
    },
    {
      classId: 5,
      currency: 'DKK',
      salesPriceInclVat: 660,
      salesPriceExclVat: 528,
      fromQty: 501,
      toQty: 2000,
    },
    {
      classId: 5,
      currency: 'DKK',
      salesPriceInclVat: 618.75,
      salesPriceExclVat: 495,
      fromQty: 2001,
      toQty: 5000,
    },
    {
      classId: 5,
      currency: 'DKK',
      salesPriceInclVat: 601.25,
      salesPriceExclVat: 481,
      fromQty: 5001,
      toQty: 10000,
    },
    {
      classId: 5,
      currency: 'DKK',
      salesPriceInclVat: 583.75,
      salesPriceExclVat: 467,
      fromQty: 10001,
      toQty: 0,
    },
    {
      classId: 5,
      currency: 'SEK',
      salesPriceInclVat: 1090,
      salesPriceExclVat: 872,
      fromQty: 1,
      toQty: 500,
    },
    {
      classId: 5,
      currency: 'SEK',
      salesPriceInclVat: 1047.5,
      salesPriceExclVat: 838,
      fromQty: 501,
      toQty: 2000,
    },
    {
      classId: 5,
      currency: 'SEK',
      salesPriceInclVat: 982.5,
      salesPriceExclVat: 786,
      fromQty: 2001,
      toQty: 5000,
    },
    {
      classId: 5,
      currency: 'SEK',
      salesPriceInclVat: 953.75,
      salesPriceExclVat: 763,
      fromQty: 5001,
      toQty: 10000,
    },
    {
      classId: 5,
      currency: 'SEK',
      salesPriceInclVat: 926.25,
      salesPriceExclVat: 741,
      fromQty: 10001,
      toQty: 0,
    },
    {
      classId: 5,
      currency: 'EUR',
      salesPriceInclVat: 92.9,
      salesPriceExclVat: 74.3,
      fromQty: 1,
      toQty: 500,
    },
    {
      classId: 5,
      currency: 'EUR',
      salesPriceInclVat: 89.3,
      salesPriceExclVat: 71.4,
      fromQty: 501,
      toQty: 2000,
    },
    {
      classId: 5,
      currency: 'EUR',
      salesPriceInclVat: 83.6,
      salesPriceExclVat: 66.9,
      fromQty: 2001,
      toQty: 5000,
    },
    {
      classId: 5,
      currency: 'EUR',
      salesPriceInclVat: 81.3,
      salesPriceExclVat: 65,
      fromQty: 5001,
      toQty: 10000,
    },
    {
      classId: 5,
      currency: 'EUR',
      salesPriceInclVat: 78.9,
      salesPriceExclVat: 63.1,
      fromQty: 10001,
      toQty: 0,
    },
    {
      classId: 6,
      currency: 'DKK',
      salesPriceInclVat: 687.5,
      salesPriceExclVat: 550,
      fromQty: 1,
      toQty: 500,
    },
    {
      classId: 6,
      currency: 'DKK',
      salesPriceInclVat: 660,
      salesPriceExclVat: 528,
      fromQty: 501,
      toQty: 2000,
    },
    {
      classId: 6,
      currency: 'DKK',
      salesPriceInclVat: 618.75,
      salesPriceExclVat: 495,
      fromQty: 2001,
      toQty: 5000,
    },
    {
      classId: 6,
      currency: 'DKK',
      salesPriceInclVat: 601.25,
      salesPriceExclVat: 481,
      fromQty: 5001,
      toQty: 10000,
    },
    {
      classId: 6,
      currency: 'DKK',
      salesPriceInclVat: 583.75,
      salesPriceExclVat: 467,
      fromQty: 10001,
      toQty: 0,
    },
    {
      classId: 6,
      currency: 'SEK',
      salesPriceInclVat: 1090,
      salesPriceExclVat: 872,
      fromQty: 1,
      toQty: 500,
    },
    {
      classId: 6,
      currency: 'SEK',
      salesPriceInclVat: 1047.5,
      salesPriceExclVat: 838,
      fromQty: 501,
      toQty: 2000,
    },
    {
      classId: 6,
      currency: 'SEK',
      salesPriceInclVat: 982.5,
      salesPriceExclVat: 786,
      fromQty: 2001,
      toQty: 5000,
    },
    {
      classId: 6,
      currency: 'SEK',
      salesPriceInclVat: 953.75,
      salesPriceExclVat: 763,
      fromQty: 5001,
      toQty: 10000,
    },
    {
      classId: 6,
      currency: 'SEK',
      salesPriceInclVat: 926.25,
      salesPriceExclVat: 741,
      fromQty: 10001,
      toQty: 0,
    },
    {
      classId: 6,
      currency: 'EUR',
      salesPriceInclVat: 92.9,
      salesPriceExclVat: 74.3,
      fromQty: 1,
      toQty: 500,
    },
    {
      classId: 6,
      currency: 'EUR',
      salesPriceInclVat: 89.3,
      salesPriceExclVat: 71.4,
      fromQty: 501,
      toQty: 2000,
    },
    {
      classId: 6,
      currency: 'EUR',
      salesPriceInclVat: 83.6,
      salesPriceExclVat: 66.9,
      fromQty: 2001,
      toQty: 5000,
    },
    {
      classId: 6,
      currency: 'EUR',
      salesPriceInclVat: 81.3,
      salesPriceExclVat: 65,
      fromQty: 5001,
      toQty: 10000,
    },
    {
      classId: 6,
      currency: 'EUR',
      salesPriceInclVat: 78.9,
      salesPriceExclVat: 63.1,
      fromQty: 10001,
      toQty: 0,
    },
    {
      classId: 9,
      currency: 'DKK',
      salesPriceInclVat: 2512,
      salesPriceExclVat: 2009.6,
    },
    {
      classId: 9,
      currency: 'SEK',
      salesPriceInclVat: 3696,
      salesPriceExclVat: 2956.8,
    },
    {
      classId: 9,
      currency: 'EUR',
      salesPriceInclVat: 0,
      salesPriceExclVat: 0,
    },
  ],
  OresundPendler: [
    {
      classId: 1,
      currency: 'SEK',
      salesPriceInclVat: 140,
      salesPriceExclVat: 112,
      fromQty: 1,
      toQty: 16,
    },
    {
      classId: 1,
      currency: 'SEK',
      salesPriceInclVat: 63,
      salesPriceExclVat: 50.4,
      fromQty: 17,
      toQty: 50,
    },
    {
      classId: 1,
      currency: 'SEK',
      salesPriceInclVat: 140,
      salesPriceExclVat: 112,
      fromQty: 51,
      toQty: 0,
    },
    {
      classId: 1,
      currency: 'DKK',
      salesPriceInclVat: 88,
      salesPriceExclVat: 70.4,
      fromQty: 1,
      toQty: 16,
    },
    {
      classId: 1,
      currency: 'DKK',
      salesPriceInclVat: 40,
      salesPriceExclVat: 32,
      fromQty: 17,
      toQty: 50,
    },
    {
      classId: 1,
      currency: 'DKK',
      salesPriceInclVat: 88,
      salesPriceExclVat: 70.4,
      fromQty: 51,
      toQty: 0,
    },
    {
      classId: 1,
      currency: 'EUR',
      salesPriceInclVat: 12,
      salesPriceExclVat: 9.6,
      fromQty: 1,
      toQty: 16,
    },
    {
      classId: 1,
      currency: 'EUR',
      salesPriceInclVat: 6,
      salesPriceExclVat: 4.8,
      fromQty: 17,
      toQty: 50,
    },
    {
      classId: 1,
      currency: 'EUR',
      salesPriceInclVat: 12,
      salesPriceExclVat: 9.6,
      fromQty: 51,
      toQty: 0,
    },
    {
      classId: 2,
      currency: 'SEK',
      salesPriceInclVat: 270,
      salesPriceExclVat: 216,
      fromQty: 1,
      toQty: 16,
    },
    {
      classId: 2,
      currency: 'SEK',
      salesPriceInclVat: 63,
      salesPriceExclVat: 50.4,
      fromQty: 17,
      toQty: 50,
    },
    {
      classId: 2,
      currency: 'SEK',
      salesPriceInclVat: 270,
      salesPriceExclVat: 216,
      fromQty: 51,
      toQty: 0,
    },
    {
      classId: 2,
      currency: 'DKK',
      salesPriceInclVat: 170,
      salesPriceExclVat: 136,
      fromQty: 1,
      toQty: 16,
    },
    {
      classId: 2,
      currency: 'DKK',
      salesPriceInclVat: 40,
      salesPriceExclVat: 32,
      fromQty: 17,
      toQty: 50,
    },
    {
      classId: 2,
      currency: 'DKK',
      salesPriceInclVat: 170,
      salesPriceExclVat: 136,
      fromQty: 51,
      toQty: 0,
    },
    {
      classId: 2,
      currency: 'EUR',
      salesPriceInclVat: 23,
      salesPriceExclVat: 18.4,
      fromQty: 1,
      toQty: 16,
    },
    {
      classId: 2,
      currency: 'EUR',
      salesPriceInclVat: 6,
      salesPriceExclVat: 4.8,
      fromQty: 17,
      toQty: 50,
    },
    {
      classId: 2,
      currency: 'EUR',
      salesPriceInclVat: 23,
      salesPriceExclVat: 18.4,
      fromQty: 51,
      toQty: 0,
    },
    {
      classId: 3,
      currency: 'SEK',
      salesPriceInclVat: 496,
      salesPriceExclVat: 396.8,
    },
    {
      classId: 3,
      currency: 'DKK',
      salesPriceInclVat: 364,
      salesPriceExclVat: 291.2,
    },
    {
      classId: 3,
      currency: 'EUR',
      salesPriceInclVat: 364,
      salesPriceExclVat: 291.2,
    },
    {
      classId: 4,
      currency: 'SEK',
      salesPriceInclVat: 940,
      salesPriceExclVat: 752,
    },
    {
      classId: 4,
      currency: 'DKK',
      salesPriceInclVat: 687.5,
      salesPriceExclVat: 550,
    },
    {
      classId: 4,
      currency: 'EUR',
      salesPriceInclVat: 940,
      salesPriceExclVat: 752,
    },
    {
      classId: 5,
      currency: 'SEK',
      salesPriceInclVat: 940,
      salesPriceExclVat: 752,
    },
    {
      classId: 5,
      currency: 'DKK',
      salesPriceInclVat: 687.5,
      salesPriceExclVat: 550,
    },
    {
      classId: 5,
      currency: 'EUR',
      salesPriceInclVat: 940,
      salesPriceExclVat: 752,
    },
    {
      classId: 6,
      currency: 'SEK',
      salesPriceInclVat: 940,
      salesPriceExclVat: 752,
    },
    {
      classId: 6,
      currency: 'DKK',
      salesPriceInclVat: 687.5,
      salesPriceExclVat: 550,
    },
    {
      classId: 6,
      currency: 'EUR',
      salesPriceInclVat: 940,
      salesPriceExclVat: 752,
    },
  ],
  Oresund_Business_Pendler: [
    {
      classId: 1,
      currency: 'SEK',
      salesPriceInclVat: 140,
      salesPriceExclVat: 112,
      fromQty: 1,
      toQty: 16,
    },
    {
      classId: 1,
      currency: 'SEK',
      salesPriceInclVat: 53,
      salesPriceExclVat: 50.4,
      fromQty: 17,
      toQty: 50,
    },
    {
      classId: 1,
      currency: 'SEK',
      salesPriceInclVat: 140,
      salesPriceExclVat: 112,
      fromQty: 51,
      toQty: 0,
    },
    {
      classId: 1,
      currency: 'DKK',
      salesPriceInclVat: 88,
      salesPriceExclVat: 70.4,
      fromQty: 1,
      toQty: 16,
    },
    {
      classId: 1,
      currency: 'DKK',
      salesPriceInclVat: 40,
      salesPriceExclVat: 32,
      fromQty: 17,
      toQty: 50,
    },
    {
      classId: 1,
      currency: 'DKK',
      salesPriceInclVat: 88,
      salesPriceExclVat: 70.4,
      fromQty: 51,
      toQty: 0,
    },
    {
      classId: 1,
      currency: 'EUR',
      salesPriceInclVat: 12,
      salesPriceExclVat: 9.6,
      fromQty: 1,
      toQty: 16,
    },
    {
      classId: 1,
      currency: 'EUR',
      salesPriceInclVat: 6,
      salesPriceExclVat: 4.8,
      fromQty: 17,
      toQty: 50,
    },
    {
      classId: 1,
      currency: 'EUR',
      salesPriceInclVat: 12,
      salesPriceExclVat: 9.6,
      fromQty: 51,
      toQty: 0,
    },
    {
      classId: 2,
      currency: 'SEK',
      salesPriceInclVat: 270,
      salesPriceExclVat: 216,
      fromQty: 1,
      toQty: 16,
    },
    {
      classId: 2,
      currency: 'SEK',
      salesPriceInclVat: 53,
      salesPriceExclVat: 50.4,
      fromQty: 17,
      toQty: 50,
    },
    {
      classId: 2,
      currency: 'SEK',
      salesPriceInclVat: 270,
      salesPriceExclVat: 216,
      fromQty: 51,
      toQty: 0,
    },
    {
      classId: 2,
      currency: 'DKK',
      salesPriceInclVat: 170,
      salesPriceExclVat: 136,
      fromQty: 1,
      toQty: 16,
    },
    {
      classId: 2,
      currency: 'DKK',
      salesPriceInclVat: 40,
      salesPriceExclVat: 32,
      fromQty: 17,
      toQty: 50,
    },
    {
      classId: 2,
      currency: 'DKK',
      salesPriceInclVat: 170,
      salesPriceExclVat: 136,
      fromQty: 51,
      toQty: 0,
    },
    {
      classId: 2,
      currency: 'EUR',
      salesPriceInclVat: 23,
      salesPriceExclVat: 18.4,
      fromQty: 1,
      toQty: 16,
    },
    {
      classId: 2,
      currency: 'EUR',
      salesPriceInclVat: 6,
      salesPriceExclVat: 4.8,
      fromQty: 17,
      toQty: 50,
    },
    {
      classId: 2,
      currency: 'EUR',
      salesPriceInclVat: 23,
      salesPriceExclVat: 18.4,
      fromQty: 51,
      toQty: 0,
    },
    {
      classId: 3,
      currency: 'SEK',
      salesPriceInclVat: 496,
      salesPriceExclVat: 396.8,
    },
    {
      classId: 3,
      currency: 'DKK',
      salesPriceInclVat: 364,
      salesPriceExclVat: 291.2,
    },
    {
      classId: 3,
      currency: 'EUR',
      salesPriceInclVat: 364,
      salesPriceExclVat: 291.2,
    },
    {
      classId: 4,
      currency: 'SEK',
      salesPriceInclVat: 940,
      salesPriceExclVat: 752,
    },
    {
      classId: 4,
      currency: 'DKK',
      salesPriceInclVat: 687.5,
      salesPriceExclVat: 550,
    },
    {
      classId: 4,
      currency: 'EUR',
      salesPriceInclVat: 940,
      salesPriceExclVat: 752,
    },
    {
      classId: 5,
      currency: 'SEK',
      salesPriceInclVat: 940,
      salesPriceExclVat: 752,
    },
    {
      classId: 5,
      currency: 'DKK',
      salesPriceInclVat: 687.5,
      salesPriceExclVat: 550,
    },
    {
      classId: 5,
      currency: 'EUR',
      salesPriceInclVat: 940,
      salesPriceExclVat: 752,
    },
    {
      classId: 6,
      currency: 'SEK',
      salesPriceInclVat: 940,
      salesPriceExclVat: 752,
    },
    {
      classId: 6,
      currency: 'DKK',
      salesPriceInclVat: 687.5,
      salesPriceExclVat: 550,
    },
    {
      classId: 6,
      currency: 'EUR',
      salesPriceInclVat: 940,
      salesPriceExclVat: 752,
    },
  ],
};
