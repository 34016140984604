import { forwardRef } from 'react';
import { link } from '../DefaultLink';
import { Typography } from '@oresundsbron/bridge-ui';
import { cx } from 'class-variance-authority';
import { LinkProps } from '../types';

export const ExternalLink: LinkProps = forwardRef(function ExLink(
  { children, className, color, underline, href, full, ...props },
  ref
) {
  return (
    <Typography
      as="a"
      ref={ref}
      target="_blank"
      href={href}
      {...props}
      className={cx(
        link({ color, full, underline }),
        className,
        props.intent ? undefined : 'font-size-inherit'
      )}
    >
      {children}
    </Typography>
  );
});
