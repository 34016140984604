import {
  documentToReactComponents,
  NodeRenderer,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Box, Table } from '@oresundsbron/bridge-ui';
import { Children, FC, ReactElement, ReactNode } from 'react';
import { flow, pipe } from 'fp-ts/lib/function';
import {
  chain,
  fromNullable,
  fromPredicate,
  getOrElseW,
  map,
  match,
  toUndefined,
} from 'fp-ts/lib/Option';
import { EmbeddedEntry } from './Entry';
import { RichText as RichTextType } from '@oresundsbron/api';
import { RichTypography } from './Typography';
import { findFirst, head, last, reduceWithIndex } from 'fp-ts/lib/Array';
import { AssetImage } from './Image';
import { cx } from 'class-variance-authority';
import { EntryHyperlink } from './EntryHyperlinks';
import { Eq, split } from 'fp-ts/lib/string';
import { toArray } from 'fp-ts/lib/ReadonlyArray';
import { AssetVideo } from './AssetVideo';
import { DefaultLink } from '../Links/DefaultLink';

export const richTextOptions = (
  { entries, assets }: RichTextType['links'],
  opts?: { color?: string }
): Options => ({
  renderText: (text) =>
    pipe(
      text.split('\n'),
      reduceWithIndex([] as ReactNode[], (index, res, segment) => [
        ...res,
        index > 0 && <br key={index} />,
        segment,
      ])
    ),

  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      return (
        <EntryHyperlink node={node} color={opts?.color || ''}>
          {children}
        </EntryHyperlink>
      );
    },
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <RichTypography as="p" {...(opts || {})}>
        {children as ReactNode}
      </RichTypography>
    ),
    [BLOCKS.HEADING_1]: (_, children) => (
      <RichTypography as="h1" {...(opts || {})}>
        {children as ReactNode}
      </RichTypography>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <RichTypography as="h2" {...(opts || {})}>
        {children as ReactNode}
      </RichTypography>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <RichTypography as="h3" {...(opts || {})}>
        {children as ReactNode}
      </RichTypography>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <RichTypography as="h4" {...(opts || {})}>
        {children as ReactNode}
      </RichTypography>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <RichTypography as="h5" {...(opts || {})}>
        {children as ReactNode}
      </RichTypography>
    ),
    [BLOCKS.UL_LIST]: (_, children) => (
      <Box as="ul" className="list-disc pl-8">
        {children}
      </Box>
    ),
    [BLOCKS.OL_LIST]: (_, children) => (
      <Box as="ol" className="list-decimal pl-8">
        {children}
      </Box>
    ),
    [BLOCKS.LIST_ITEM]: (_, children) => (
      <Box
        as="li"
        className={pipe(
          Children.toArray(children as ReactNode),
          head,
          map((e) => (e as ReactElement).props.color),
          toUndefined
        )}
      >
        {children}
      </Box>
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.includes('player.vimeo.com/video')) {
        return (
          <iframe
            title={
              typeof node.content[0] === 'object' && 'value' in node.content[0]
                ? node.content[0].value
                : ''
            }
            src={node.data.uri}
            allowFullScreen
            className="aspect-video w-full"
          ></iframe>
        );
      } else if (node.data.uri.includes('youtube.com/')) {
        const videoEmbedUrl = node.data.uri.includes('youtube.com/embed')
          ? node.data.uri
          : `https://www.youtube.com/embed/${new URL(
              node.data.uri
            ).searchParams.get('v')}`;
        return (
          <iframe
            title={
              typeof node.content[0] === 'object' && 'value' in node.content[0]
                ? node.content[0].value
                : ''
            }
            src={videoEmbedUrl}
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="aspect-video w-full"
          ></iframe>
        );
      } else if (
        typeof node.content[0] === 'object' &&
        'value' in node.content[0]
      ) {
        return (
          <DefaultLink href={node.data.uri} className={cx(opts?.color)}>
            {children}
          </DefaultLink>
        );
      }
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const asset = pipe(
        assets.hyperlink,
        findFirst((x) => x.sys.id === node.data.target.sys.id),
        getOrElseW(() => undefined)
      );

      if (!asset) {
        return null;
      }

      const fileType = pipe(
        asset.contentType,
        fromNullable,
        chain(flow(split('/'), toArray, last)),
        match(
          () => null,
          (format) => format
        )
      );

      return (
        <DefaultLink href={asset.url} className={cx(opts?.color)}>
          {children} {fileType && `(.${fileType})`}
        </DefaultLink>
      );
    },
    [BLOCKS.TABLE]: (_, children) => {
      const [headers, ...rest] = Children.toArray(children as ReactNode);

      return (
        <Table.Root scroll="horizontal" className="mb-4">
          <thead>{headers}</thead>
          <tbody>{rest}</tbody>
        </Table.Root>
      );
    },
    [BLOCKS.TABLE_ROW]: (node, children) => {
      let enHyperLink;

      JSON.stringify(node, (_, nestedVal) =>
        pipe(
          nestedVal,
          fromPredicate(
            (v) => !!v && Eq.equals(v['nodeType'], INLINES.ENTRY_HYPERLINK)
          ),
          match(
            () => nestedVal,
            () => (enHyperLink = nestedVal)
          )
        )
      );

      return (
        <Table.Row
          className={`border-b border-b-black last:mb-2 ${
            !!enHyperLink ? '[&_.underline]:no-underline' : ''
          }`}
        >
          {children}
        </Table.Row>
      );
    },
    [BLOCKS.TABLE_HEADER_CELL]: (_, children) => (
      <Table.Heading className="p-4 text-left align-top last:pr-0 md:pt-6 md:pb-0 md:pl-0 md:pr-2 md:align-middle">
        {children}
      </Table.Heading>
    ),
    [BLOCKS.TABLE_CELL]: (_, children) => (
      <Table.Cell className="p-4 text-left align-top last:pr-0 md:pt-6 md:pb-0 md:pl-0 md:pr-2 md:align-middle">
        {children}
      </Table.Cell>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: EmbeddedEntry(entries.block) as NodeRenderer,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const img = pipe(
        assets.block,
        findFirst((x) => x.sys.id === node.data.target.sys.id),
        getOrElseW(() => undefined)
      );

      if (!img) {
        return null;
      }

      if (img.url?.includes('.mp4')) {
        return <AssetVideo {...img} />;
      } else {
        return <AssetImage {...img} />;
      }
    },
  },
});

export const RichText: FC<{
  color?: string;
  content?: RichTextType;
}> = ({ content, color }) =>
  pipe(
    content,
    fromNullable,
    map(({ json, links }) => (
      <>
        {documentToReactComponents(
          json,
          richTextOptions(links || { entries: { block: [] } }, { color })
        )}
      </>
    )),
    getOrElseW(() => null)
  );
