import { Asset } from '@oresundsbron/api';
import { FC } from 'react';

export const AssetVideo: FC<Asset> = ({ url }) => (
  <div className="flex w-full items-center justify-center">
    <div className="w-full">
      <video className="w-full" controls src={url} controlsList="nodownload" />
    </div>
  </div>
);
