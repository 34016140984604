import { ContentfulQueryKeys, useContentfulQuery } from '@oresundsbron/api';
import { useRouter } from 'next/router';

export function useInformationBanner() {
  const { locale, isPreview } = useRouter();
  const { data } = useContentfulQuery(
    ContentfulQueryKeys.InformationBanner({ locale, preview: isPreview }),
    (q) => q.InformationBanner.list({ locale, preview: isPreview })
  );

  return {
    data,
  };
}
