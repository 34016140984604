import { Currency } from '@oresundsbron/validators';
import { pipe } from 'fp-ts/lib/function';
import { getOrElse } from 'fp-ts/lib/Option';
import { lookup } from 'fp-ts/lib/Record';
import {} from 'next/router';

const currencyLocaleMap: Record<string, Currency> = {
  sv: 'SEK',
  da: 'DKK',
};

const localeMap: Record<string, string> = {
  sv: 'sv-SE',
  da: 'da-DK',
  de: 'de-DE',
  en: 'en-UK',
};

export const getDefaultCurrency = (locale: string) =>
  pipe(
    currencyLocaleMap,
    lookup(locale),
    getOrElse(() => 'DKK' as Currency)
  );

export const toCurrency = (
  value: number,
  locale?: string,
  currency?: Currency
) => {
  return new Intl.NumberFormat(localeMap[locale || ''] || 'sv-SE', {
    style: 'currency',
    currency: currency || getDefaultCurrency(locale || ''),
    currencyDisplay: 'code',
  }).format(value);
};
