import { CustomerType } from '@oresundsbron/validators';
import create from 'zustand';
import { persist } from 'zustand/middleware';

export type AuthStore = {
  token: string;
  customerType: CustomerType;
  isAuthenticated: boolean;
  isOresundPay: boolean;
  identifier: string;
  userCode: string;
};

export const useAuth = create<AuthStore>()(
  persist(
    (_) => ({
      token: '',
      customerType: 'private',
      isAuthenticated: false,
      isOresundPay: false,
      identifier: '',
      userCode: '',
    }),
    {
      name: 'auth-storage',
    }
  )
);

export const getAuthToken = () => useAuth.getState().token;
export const getIsOresundPay = () => !!useAuth.getState().isOresundPay;
export const getCustomerType = () => useAuth.getState().customerType;
export const getUserCode = () => useAuth.getState().userCode;
export const setAuthToken = (token: string, isOPay?: boolean) =>
  useAuth.setState({ token, isAuthenticated: true, isOresundPay: !!isOPay });
export const setUserCode = (userCode: string) => useAuth.setState({ userCode });
export const authenticate = (
  token: string,
  customerType: CustomerType,
  identifier: string,
  isOPay?: boolean
) =>
  useAuth.setState({
    token,
    customerType,
    isAuthenticated: !!token,
    isOresundPay: !!isOPay,
    identifier: identifier,
    userCode: '',
  });
export const resetAuthentication = () =>
  useAuth.setState({
    token: '',
    customerType: 'private',
    isAuthenticated: false,
    isOresundPay: false,
  });
