import { PriceList as PriceListType } from '@oresundsbron/api';
import { Box, Table, Typography } from '@oresundsbron/bridge-ui';
import { FC, ReactNode, useMemo } from 'react';
import { Currency } from '@oresundsbron/type-safeguards';
import { toCurrency } from '../../../../lib/currency';
import { useTranslation } from 'next-i18next';
import { pipe } from 'fp-ts/lib/function';
import { findFirst, head, reduce } from 'fp-ts/lib/Array';
import { fold, map, toUndefined } from 'fp-ts/lib/Option';
import { Price, VariantWithClassID, groupPricesBy } from './utils';
import { useRouter } from 'next/router';

export const PriceListOresundCommuter: FC<
  Omit<PriceListType, 'variants'> & {
    prices: Price[];
    variants: VariantWithClassID[];
  }
> = ({ variants, prices }) => {
  const { locale } = useRouter();
  const { t } = useTranslation(['common']);

  const items = useMemo(() => pipe(prices, groupPricesBy('fromQty')), [prices]);

  return (
    <Box>
      <Table.Root scroll="horizontal">
        <Box as="thead">
          <Table.Row>
            <Table.Heading></Table.Heading>
            {variants.map((v) => (
              <Table.Heading
                as="th"
                key={v.classID}
                className="whitespace-nowrap pl-4 text-right first:pl-0"
              >
                {t(`vehicles.${v.variant}`)}
              </Table.Heading>
            ))}
          </Table.Row>
        </Box>
        <Box as="tbody">
          {items.map((v, i) => (
            <Table.Row className="border-b" key={i}>
              {pipe(
                v,
                head,
                map((x) => (
                  <Table.Cell className="py-4">
                    <Typography className="flex-[2] whitespace-nowrap sm:flex-[3]">
                      {`${t('price.oneWay')} ${x.fromQty}-${
                        !!x.toQty ? x.toQty : ''
                      }`}
                    </Typography>
                  </Table.Cell>
                )),
                toUndefined
              )}
              {pipe(
                variants,
                reduce([] as ReactNode[], (res, variant) =>
                  pipe(
                    v,
                    findFirst((x) => `${x.classId}` === variant.classID),
                    map((x) => (
                      <Table.Cell key={x.classId} className="py-4">
                        <Typography className="flex-1 text-right">
                          {toCurrency(
                            x.salesPriceInclVat,
                            locale,
                            x.currency as Currency
                          )}
                        </Typography>
                      </Table.Cell>
                    )),
                    fold(
                      () => res,
                      (comp) => [...res, comp]
                    )
                  )
                )
              )}
            </Table.Row>
          ))}
        </Box>
      </Table.Root>
      <Typography className="w-max-[60ch] mt-4" intent="label">
        {t('price.commuter.disclaimer')}
      </Typography>
    </Box>
  );
};
