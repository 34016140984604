import {
  Box,
  Button,
  Grid,
  PageWrapper,
  Typography,
} from '@oresundsbron/bridge-ui';
import { cva } from 'class-variance-authority';
import * as Dialog from '@radix-ui/react-dialog';
import { Close, Hamburger } from '@oresundsbron/icons';
import { useNavigation } from '../hooks/useNavigation';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { pipe } from 'fp-ts/lib/function';
import { filter, map } from 'fp-ts/lib/Array';
import { isNavigationSection } from '../lib/navigation';
import { useLinks } from '../hooks/useLinks';
import { DefaultLink } from '~/components/Links/DefaultLink';

const wrapper = cva(
  ['fixed', 'inset-0', 'z-30', 'overflow-y-auto', 'bg-primary-600'],
  {
    variants: {
      open: {
        true: ['block'],
        false: ['hidden'],
      },
    },
    defaultVariants: {
      open: true,
    },
  }
);

export const MainNavigation = () => {
  const [open, toggleOpen] = useState(false);
  const { events } = useRouter();
  const data = useNavigation('Main');
  const { getLink } = useLinks();

  const sections = useMemo(
    () =>
      pipe(
        data?.sections || [],
        filter(isNavigationSection),
        map(({ sys, title, links }) => (
          <Box as="li" key={sys.id}>
            <Typography
              as="h2"
              intent="headline"
              color="white"
              className="mb-4"
            >
              {title}
            </Typography>
            <Box as="ul" className="flex flex-col gap-2">
              {links.map(({ sys, pageLink, title }) => (
                <Box as="li" key={sys.id}>
                  <DefaultLink
                    href={getLink(pageLink?.sys?.id)?.path || ''}
                    className="!text-base font-light text-white hover:text-white"
                    intent="label"
                    underline={false}
                  >
                    {title}
                  </DefaultLink>
                </Box>
              ))}
            </Box>
          </Box>
        ))
      ),
    [data, getLink]
  );

  useEffect(() => {
    events.on('routeChangeComplete', () => toggleOpen(false));
    return () => {
      events.off('routeChangeComplete', () => toggleOpen(false));
    };
  }, [events]);

  return (
    <Dialog.Root open={open} onOpenChange={(o) => toggleOpen(o)}>
      <Dialog.Trigger asChild>
        <Button
          intent="text"
          color="neutral"
          iconSize="lg"
          startIcon={<Hamburger />}
        ></Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-20 bg-black/75" />
        <Dialog.Content className={wrapper({})}>
          <Dialog.Close asChild>
            <Button
              color="white"
              iconSize="lg"
              className="absolute top-4 right-3 sm:top-8 sm:right-10"
              startIcon={<Close />}
            ></Button>
          </Dialog.Close>
          <PageWrapper className="py-8 md:py-[9rem]" as="nav">
            <Grid className="col-start-2">
              <Box
                as="ul"
                className="col-span-full grid grid-cols-1 gap-6 sm:grid-cols-3"
              >
                {sections}
              </Box>
            </Grid>
          </PageWrapper>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
