import { Block, Inline } from '@contentful/rich-text-types';
import { FC, PropsWithChildren, useMemo } from 'react';
import { pipe } from 'fp-ts/lib/function';
import { fromNullable, getOrElseW } from 'fp-ts/lib/Option';
import { useLinks } from '../../hooks/useLinks';
import { cx } from 'class-variance-authority';
import { DefaultLink } from '../Links/DefaultLink';

export const EntryHyperlink: FC<
  PropsWithChildren<{ color: string; node: Block | Inline }>
> = ({ children, color, node }) => {
  const { getLink } = useLinks();

  const link = useMemo(
    () =>
      pipe(
        getLink(node?.data?.target?.sys?.id),
        fromNullable,
        getOrElseW(() => undefined)
      ),
    [getLink, node.data.target.sys.id]
  );

  if (!link) {
    return <>{children}</>;
  }
  return (
    <DefaultLink href={link.path} className={cx(color)}>
      {children}
    </DefaultLink>
  );
};
