import { z } from 'zod';
import { pipe } from 'fp-ts/lib/function';
import { priceGroupParser } from '@oresundsbron/validators';
import { reduce } from 'fp-ts/lib/Array';
import { lookup, toArray } from 'fp-ts/lib/Record';
import { getOrElse, map } from 'fp-ts/lib/Option';

export type Price = z.infer<typeof priceGroupParser>;

export type VariantWithClassID = {
  variant: string;
  classID: string;
};

export const groupPricesBy = (k: keyof Price) => (arr: Price[]) =>
  pipe(
    arr,
    reduce({} as Record<string, Price[]>, (res, item) =>
      pipe(
        res,
        lookup(`${item[k]}`),
        map((curr) => [...curr, item]),
        map((items) => ({ ...res, [`${item[k]}`]: items })),
        getOrElse(() => ({ ...res, [`${item[k]}`]: [item] }))
      )
    ),
    toArray,
    reduce([] as Price[][], (res, [, items]) => [...res, items])
  );
