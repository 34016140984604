import { BackendQueryKeys, useTypeSafeQuery } from '@oresundsbron/api';
import {
  bridgeMessageParser,
  bridgeStatusParser,
} from '@oresundsbron/validators';
import { pipe } from 'fp-ts/lib/function';
import { fromNullable, getOrElse, map } from 'fp-ts/lib/Option';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { z } from 'zod';

type StatusDetail = z.infer<typeof bridgeMessageParser>;
export type BridgeStatus = z.output<typeof bridgeStatusParser>;

export function useBridgeStatus() {
  const { locale } = useRouter();
  const bridgeStatus = useTypeSafeQuery(
    BackendQueryKeys.BrigdeStatus(),
    (req) => req.BridgeStatus.status(),
    {
      retry: 2,
      refetchInterval: 30000, // poll interval 30 sec
      refetchIntervalInBackground: true,
      keepPreviousData: true,
    }
  );

  const details = useTypeSafeQuery(
    BackendQueryKeys.BrigdeStatusDetails({ locale }),
    (req) => req.BridgeStatus.statusDetails(),
    {
      retry: 2,
      refetchInterval: 30000, // poll interval 30 sec
      refetchIntervalInBackground: true,
      keepPreviousData: true,
    }
  );

  const info = useMemo(
    () =>
      pipe(
        details.data,
        fromNullable,
        map((data) => data.info),
        getOrElse(() => [] as StatusDetail[])
      ),
    [details.data]
  );

  const status = useMemo(
    () =>
      pipe(
        bridgeStatus.data,
        fromNullable,
        map((data) => data.status),
        getOrElse(() => 'unknown' as BridgeStatus['status'])
      ),
    [bridgeStatus.data]
  );

  return {
    data: {
      info,
      status,
    },
    isStatusLoading: bridgeStatus.isLoading || !bridgeStatus.isFetched,
    isDetailsLoading: details.isLoading || !details.isFetched,
  };
}
