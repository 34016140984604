import {
  ContentfulQueryKeys,
  Navigation,
  useContentfulQuery,
} from '@oresundsbron/api';
import { useRouter } from 'next/router';

export function useNavigation(type: Navigation['type']) {
  const { locale, isPreview } = useRouter();

  const { data } = useContentfulQuery(
    ContentfulQueryKeys.Navigation({ locale, type, preview: isPreview }),
    (req) => req.Navigation.get({ locale, type, preview: isPreview })
  );

  return data;
}
