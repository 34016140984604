import {
  BaseTypeMap,
  TypographyProps,
  Component,
} from '@oresundsbron/bridge-ui';
import { VariantProps } from 'class-variance-authority';
import { link } from './DefaultLink';

type Link = BaseTypeMap<
  'a',
  Omit<TypographyProps, 'color'> & VariantProps<typeof link> & AddedFields
>;

type AddedFields = {
  active?: boolean;
  href: string;
  navLinkWrapper?: boolean;
};

export type LinkProps = Component<Link>;

export enum LinkSwitchType {
  internal = 'internal',
  external = 'external',
}
