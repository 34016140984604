import { ButtonLink as ButtonLinkEntry } from '@oresundsbron/api';
import { Button, ButtonProps } from '@oresundsbron/bridge-ui';
import { pipe } from 'fp-ts/lib/function';
import { altW, chain, fromNullable, getOrElseW, map } from 'fp-ts/lib/Option';
import Link from 'next/link';
import { FC, useMemo } from 'react';
import { useLinks } from '../../../hooks/useLinks';

export const ButtonLink: FC<ButtonLinkEntry> = ({
  text,
  variant,
  pageLink,
  externalUrl,
  inverted,
}) => {
  const { getLink } = useLinks();

  const { locale, path, target } = useMemo(
    () =>
      pipe(
        pageLink,
        fromNullable,
        map((p) => p.sys.id),
        chain((id) => pipe(id, getLink, fromNullable)),
        map((link) => ({
          locale: link.locale,
          path: link.path,
          target: undefined,
        })),
        altW(() =>
          pipe(
            externalUrl,
            fromNullable,
            map((url) => ({ locale: undefined, path: url, target: '_blank' }))
          )
        ),
        getOrElseW(() => ({
          locale: undefined,
          path: undefined,
          target: undefined,
        }))
      ),
    [pageLink, externalUrl, getLink]
  );

  return (
    <Button
      className="mr-4"
      as={Link}
      href={path || ''}
      locale={locale}
      color={inverted ? 'white' : undefined}
      intent={variant as ButtonProps['intent']}
      target={target}
    >
      {text}
    </Button>
  );
};
