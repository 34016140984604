import { PriceList as PriceListType } from '@oresundsbron/api';
import { pipe } from 'fp-ts/lib/function';
import { map as mapO, toUndefined } from 'fp-ts/lib/Option';
import { FC, useMemo } from 'react';
import { lookup } from 'fp-ts/lib/Record';
import { filter, reduce, map, concat } from 'fp-ts/lib/Array';
import { getDefaultCurrency } from '../../../../lib/currency';
import { vehiclePrices, Vehicles } from '../../../../lib/vehicle';
import { PriceListOresundGO } from './OresundGO';
import { PriceListOresundCommuter } from './OresundCommuter';
import { PriceListOresundBusinessCommuter } from './OresundBusinessCommuter';
import { PriceListOresundBusiness } from './OresundBusiness';
import { useRouter } from 'next/router';
import { VariantWithClassID } from './utils';

export const PriceList: FC<PriceListType> = ({
  variants,
  contractType,
  ...rest
}) => {
  const { locale } = useRouter();

  const variantsWithClassID = useMemo(
    () =>
      pipe(
        variants,
        reduce([] as VariantWithClassID[], (res, variant) =>
          pipe(
            Vehicles,
            filter((v) => v.label === `vehicles.${variant}`),
            map((v) => ({ variant, classID: v.classID })),
            concat(res)
          )
        )
      ),
    [variants]
  );

  const prices = useMemo(
    () =>
      pipe(
        vehiclePrices,
        lookup(contractType),
        mapO(filter((x) => x.currency === getDefaultCurrency(locale || ''))),
        mapO(
          filter((x) =>
            variantsWithClassID.some((c) => c.classID === `${x.classId}`)
          )
        ),
        toUndefined
      ),
    [locale, contractType, variantsWithClassID]
  );

  if (!prices) {
    return null;
  }

  if (contractType === 'OresundGO') {
    return (
      <PriceListOresundGO
        {...rest}
        variants={variantsWithClassID}
        contractType={contractType}
        prices={prices}
      />
    );
  }

  if (contractType === 'OresundPendler') {
    return (
      <PriceListOresundCommuter
        {...rest}
        variants={variantsWithClassID}
        contractType={contractType}
        prices={prices}
      />
    );
  }

  if (contractType === 'Oresund_Business') {
    return (
      <PriceListOresundBusiness
        {...rest}
        variants={variantsWithClassID}
        contractType={contractType}
        prices={prices}
      />
    );
  }

  if (contractType === 'Oresund_Business_Pendler') {
    return (
      <PriceListOresundBusinessCommuter
        {...rest}
        variants={variantsWithClassID}
        contractType={contractType}
        prices={prices}
      />
    );
  }

  return null;
};
