import { forwardRef } from 'react';
import { link } from '../DefaultLink';
import { Typography } from '@oresundsbron/bridge-ui';
import { cx } from 'class-variance-authority';
import Link from 'next/link';
import { LinkProps } from '../types';

export const InternalLink: LinkProps = forwardRef(function ILink(
  { children, className, color, underline, href, full, active, ...props },
  ref
) {
  return (
    <Link href={href} passHref legacyBehavior>
      <Typography
        as="a"
        active={active ? 'true' : undefined}
        data-active={active}
        ref={ref}
        {...props}
        className={cx(
          link({ color, full, underline }),
          className,
          props.intent ? undefined : 'font-size-inherit'
        )}
      >
        {children}
      </Typography>
    </Link>
  );
});
