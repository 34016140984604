import { Grid, PageWrapper } from '@oresundsbron/bridge-ui';
import { filter, map } from 'fp-ts/lib/Array';
import { pipe } from 'fp-ts/lib/function';
import { FC, useMemo } from 'react';
import { useLinks } from '../../hooks/useLinks';
import { useNavigation } from '../../hooks/useNavigation';
import { isNavigationSection } from '../../lib/navigation';
import FooterSection, { type FooterSectionProps } from './FooterSection';
import { useFlags } from '~/components/Flags';
import Chatbot from '../Chatbot';
import { useRouter } from 'next/router';

const Footer: FC = () => {
  const { getLink } = useLinks();
  const router = useRouter();
  const { asPath } = router;
  const nav = useNavigation('Footer');
  const flags = useFlags();

  // REmove this when chatbot should be visible on all pages.
  const isOnAValidChatBotPage = useMemo(() => {
    const pathsWithChatbot = [
      '/faq',
      '/kontakta-oss',
      '/contact-us',
      '/kontakt-os',
    ];
    return pathsWithChatbot.some((text) => asPath.includes(text));
  }, [asPath]);

  const sections = useMemo(
    () =>
      pipe(
        nav?.sections || [],
        filter(isNavigationSection),
        map(({ title, links, pageLink, sys }) => ({
          title,
          links,
          link: getLink(pageLink?.sys.id ?? ''),
          id: sys.id,
        })),
        map((section) => (
          <FooterSection
            key={section.id}
            {...(section as FooterSectionProps)}
          />
        ))
      ),
    [nav?.sections, getLink]
  );

  return (
    <PageWrapper
      as="footer"
      className="bg-primary-950 auto-rows-fr py-4 print:hidden"
    >
      <Grid
        as="nav"
        className="relative col-start-2 min-h-[20rem] py-6 text-center md:min-h-[24rem] md:py-12"
        aria-label={nav?.title}
      >
        {sections}
      </Grid>
      {flags.get('chatbot') === true && isOnAValidChatBotPage && <Chatbot />}
    </PageWrapper>
  );
};

export default Footer;
