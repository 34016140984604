import { Box } from '@oresundsbron/bridge-ui';
import { Trans } from 'next-i18next';
import { FC } from 'react';
import { ContractTranslationKey } from '../lib/contract-translations';

export const ContractName: FC<{
  contract: ContractTranslationKey;
  ns?: string;
}> = ({ contract, ns = 'common' }) => (
  <Trans ns={ns} i18nKey={contract}>
    <Box as="span" className="font-bold text-inherit" />
  </Trans>
);
