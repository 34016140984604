import { FC, useMemo } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Box, Button, Card, Typography } from '@oresundsbron/bridge-ui';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import { ThemeJSX } from '@oresundsbron/theme';

type Flags = {
  [key: string]: ThemeJSX.Element;
};

const SV = () => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    aria-hidden="true"
  >
    <g clipPath="url(#clip0_8930_75273)">
      <path d="M0 0H24V18H0V0Z" fill="#005293" />
      <path
        d="M6.6 0V7.2H0V10.8H6.6V18H10.2V10.8H24V7.2H10.2V0H6.6Z"
        fill="#FECB00"
      />
    </g>
    <defs>
      <clipPath id="clip0_8930_75273">
        <rect width="24" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DA = () => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    aria-hidden="true"
  >
    <g clipPath="url(#clip0_8930_75274)">
      <path d="M0 0H24.0037V18H0V0Z" fill="#C8102E" />
      <path d="M7.71375 0H10.2862V18H7.71375V0Z" fill="white" />
      <path d="M0 7.71375H24.0037V10.2862H0V7.71375Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_8930_75274">
        <rect width="24" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const EN = () => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    aria-hidden="true"
  >
    <g clipPath="url(#clip0_8930_75275)">
      <path d="M0 0H24V18H0V0Z" fill="#012169" />
      <path
        d="M2.8125 0L11.9625 6.7875L21.075 0H24V2.325L15 9.0375L24 15.7125V18H21L12 11.2875L3.0375 18H0V15.75L8.9625 9.075L0 2.4V0H2.8125Z"
        fill="white"
      />
      <path
        d="M15.9 10.5375L24 16.5V18L13.8375 10.5375H15.9ZM9 11.2875L9.225 12.6L2.025 18H0L9 11.2875ZM24 0V0.1125L14.6625 7.1625L14.7375 5.5125L22.125 0H24ZM0 0L8.9625 6.6H6.7125L0 1.575V0Z"
        fill="#C8102E"
      />
      <path d="M9.0375 0V18H15.0375V0H9.0375ZM0 6V12H24V6H0Z" fill="white" />
      <path
        d="M0 7.2375V10.8375H24V7.2375H0ZM10.2375 0V18H13.8375V0H10.2375Z"
        fill="#C8102E"
      />
    </g>
    <defs>
      <clipPath id="clip0_8930_75275">
        <rect width="24" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const flags: Flags = {
  sv: <SV />,
  da: <DA />,
  en: <EN />,
};

const translations = { sv: 'swedish', en: 'english', da: 'danish' };

export const LanguagePicker: FC = () => {
  const { t } = useTranslation(['common']);
  const { push, asPath, locales, locale } = useRouter();
  const queryClient = useQueryClient();

  const redirect = useMemo(
    () =>
      queryClient.getQueryData([
        'page',
        'redirects',
        {
          locale,
          slug: asPath.split('?')[0].split('#')[0].split('/').filter(Boolean), // Remove hash parameters and query parameters from URL, else language selector will not work. See devops ticket 5687
        },
      ]),
    [asPath, locale, queryClient]
  );

  const currentFlag = useMemo(() => {
    const flag = flags[locale || ''];
    return typeof flag !== 'undefined' ? flag : undefined;
  }, [locale]);

  const items = useMemo(() => {
    if (redirect) {
      return Object.entries(redirect).map(([k, v]) => (
        <DropdownMenu.Item
          key={k}
          className="cursor-pointer"
          onSelect={() => push(v, undefined, { locale: k })}
        >
          <Typography className="flex items-center gap-4 py-3 px-4">
            {flags[k as keyof typeof flags]}
            <Box as="span">
              {t(translations[k as keyof typeof translations])}
            </Box>
          </Typography>
        </DropdownMenu.Item>
      ));
    } else if (
      asPath.includes('/account/') ||
      asPath.includes('/news/') ||
      asPath.includes('/club/')
    ) {
      // When you're on an individual news page (eg http://localhost:3000/sv/news/anmalan-till-broloppet-2025-oppnar-den-1-februari/3405890) we cannot
      // switch to the same news item in another languange since they are two completely separate entities.
      // SV news items are from TT, DA news items are from Ritzau. They have nothing in common. EN has no news at all.
      // Because of that, just redirect to startpage.
      // The same behaviour is needed for individual club offer pages.

      const redirectPath = asPath.includes('/account/') ? asPath : '/';

      return (locales || [])
        .filter((x) => x !== locale)
        .map((loc) => (
          <DropdownMenu.Item
            key={loc}
            className="cursor-pointer"
            onSelect={() => push(redirectPath, undefined, { locale: loc })}
          >
            <Typography className="flex items-center gap-4 py-3 px-4">
              {flags[loc as keyof typeof flags]}
              <Box as="span">
                {t(translations[loc as keyof typeof translations])}
              </Box>
            </Typography>
          </DropdownMenu.Item>
        ));
    }
  }, [redirect, t, push, locales, asPath, locale]);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button
          aria-label={t(translations[locale as keyof typeof translations])}
          padding={false}
          intent="link"
          disabled={!items}
        >
          <Box as="span" className="flex gap-2">
            {currentFlag}
            {!!items ? (
              <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none">
                <mask
                  id="mask0_9092_29380"
                  style={{ maskType: 'alpha' }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_9092_29380)">
                  <path
                    d="M11.9997 14.95C11.8664 14.95 11.7414 14.929 11.6247 14.887C11.508 14.8456 11.3997 14.775 11.2997 14.675L6.6747 10.05C6.49136 9.86664 6.40403 9.63731 6.4127 9.36198C6.4207 9.08731 6.51636 8.85831 6.6997 8.67498C6.88303 8.49164 7.11636 8.39998 7.3997 8.39998C7.68303 8.39998 7.91636 8.49164 8.0997 8.67498L11.9997 12.575L15.9247 8.64998C16.108 8.46664 16.3374 8.37898 16.6127 8.38698C16.8874 8.39564 17.1164 8.49164 17.2997 8.67498C17.483 8.85831 17.5747 9.09164 17.5747 9.37498C17.5747 9.65831 17.483 9.89164 17.2997 10.075L12.6997 14.675C12.5997 14.775 12.4914 14.8456 12.3747 14.887C12.258 14.929 12.133 14.95 11.9997 14.95Z"
                    fill="#404651"
                  />
                </g>
              </svg>
            ) : null}
          </Box>
        </Button>
      </DropdownMenu.Trigger>
      {!!items ? (
        <DropdownMenu.Portal>
          <Card.Root
            as={DropdownMenu.Content}
            className="z-20 rounded-md bg-white p-2"
            rounded={false}
            sideOffset={5}
          >
            {items}
          </Card.Root>
        </DropdownMenu.Portal>
      ) : null}
    </DropdownMenu.Root>
  );
};
