import { Typography, TypographyProps } from '@oresundsbron/bridge-ui';
import { cva, cx } from 'class-variance-authority';
import { pipe } from 'fp-ts/lib/function';
import { Do, apS, getOrElseW } from 'fp-ts/lib/Option';
import { lookup } from 'fp-ts/lib/Record';
import { FC, PropsWithChildren } from 'react';

type Tag = 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

const intentMap: Record<Tag, TypographyProps['intent']> = {
  h1: 'display',
  h2: 'headline',
  h3: 'title',
  h4: 'title',
  h5: 'title',
  p: 'body',
};

const sizeMap: Record<Tag, TypographyProps['size']> = {
  h1: 'md',
  h2: 'sm',
  h3: 'lg',
  h4: 'md',
  h5: 'sm',
  p: 'lg',
};

const margin = cva(['first:pt-0', 'last:pb-0'], {
  variants: {
    tag: {
      h1: ['pb-6'],
      h2: ['pt-8', 'pb-4'],
      h3: ['pt-6', 'pb-4'],
      h4: ['pt-4', 'pb-4'],
      h5: ['pt-10', 'sm:pt-9', 'pb-4'],
      p: ['pb-6'],
    },
  },
});

const getTypographyStyling = (
  tag: Tag
): Pick<TypographyProps, 'intent' | 'size'> =>
  pipe(
    Do,
    apS('intent', pipe(intentMap, lookup(tag))),
    apS('size', pipe(sizeMap, lookup(tag))),
    getOrElseW(() => ({ intent: undefined, size: undefined }))
  );

export const RichTypography: FC<
  PropsWithChildren<{
    color?: string;
    as?: Tag;
  }>
> = ({ children, color, as = 'p' }) => {
  return (
    <Typography
      className={cx(color, margin({ tag: as }))}
      as={as}
      {...getTypographyStyling(as)}
    >
      {children}
    </Typography>
  );
};
