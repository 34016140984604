import { pipe } from 'fp-ts/lib/function';
import { getOrElse } from 'fp-ts/lib/Option';
import en from 'date-fns/locale/en-GB';
import sv from 'date-fns/locale/sv';
import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import create from 'zustand';
import { lookup } from 'fp-ts/lib/Record';

export type LocaleStore = {
  locale: string;
  dateLocale?: Locale;
};

const dateLocaleMap = {
  en,
  sv,
  da,
  de,
} as const;

const getDateLocale = (locale: string) =>
  pipe(
    dateLocaleMap,
    lookup(locale),
    getOrElse(() => en)
  );

export const useLocale = create<LocaleStore>(() => ({
  locale: '',
}));

export const setLocale = (locale: string) =>
  useLocale.setState(() => ({ locale, dateLocale: getDateLocale(locale) }));
