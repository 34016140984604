import { Box, Card, Typography } from '@oresundsbron/bridge-ui';
import { ClubList as ClubListType } from '@oresundsbron/api';
import { FC, useMemo } from 'react';
import { pipe } from 'fp-ts/lib/function';
import {
  fromNullable,
  map,
  toUndefined,
  fromPredicate,
  getOrElse,
  chain,
} from 'fp-ts/lib/Option';
import { map as mapA, head } from 'fp-ts/lib/Array';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useLinks } from '~/hooks/useLinks';
import { DefaultLink } from '~/components/Links/DefaultLink';

export const ClubList: FC<ClubListType> = ({ articles }) => {
  const { t } = useTranslation(['club', 'common'], { nsMode: 'fallback' });
  const { getLink } = useLinks();

  const articleItems = useMemo(
    () =>
      pipe(
        articles,
        fromNullable,
        map((c) => c.items),
        map(
          mapA((item) =>
            pipe(
              item,
              fromPredicate((x) => x.__typename === 'ClubOffer'),
              map(({ sys, title, images, category, location }) => (
                <Card.Root
                  key={sys.id}
                  className="group relative flex flex-col-reverse"
                  as="article"
                >
                  <Card.Header className="flex h-32 flex-col justify-between bg-neutral-200">
                    <Typography className="mb-2 flex gap-2">
                      {category ? (
                        <Typography
                          as="span"
                          className="text-primary-400 uppercase"
                          weight="medium"
                          size="sm"
                        >
                          {category.externalName}
                        </Typography>
                      ) : null}
                      {location ? (
                        <Typography
                          as="span"
                          className="text-neutral-500"
                          size="sm"
                        >
                          {location.externalName}
                        </Typography>
                      ) : null}
                    </Typography>

                    <Typography as="h2" intent="title">
                      <DefaultLink
                        href={getLink(sys.id)?.path || ''}
                        color="inherit"
                        full
                      >
                        {title}
                      </DefaultLink>
                    </Typography>
                  </Card.Header>
                  <Card.Content className="relative h-64 overflow-hidden">
                    {pipe(
                      images.items,
                      head,
                      chain(fromNullable),
                      map((img) => (
                        <Image
                          key={img.sys.id}
                          className="transition ease-in-out group-hover:scale-110"
                          alt={img.description || ''}
                          src={img.url}
                          sizes="(max-width: 768px) 50vw,
                        20vw"
                          quality={50}
                          fill
                          style={{
                            objectFit: 'cover',
                            aspectRatio: '16/9',
                          }}
                        />
                      )),
                      toUndefined
                    )}
                    {pipe(
                      item.voucher,
                      fromNullable,
                      map(({ discount }) => (
                        <Box className="absolute top-0 right-0 rounded-bl-3xl bg-purple-600 py-2 px-4">
                          <Typography color="white" intent="title">
                            {discount}
                          </Typography>
                        </Box>
                      )),
                      toUndefined
                    )}
                  </Card.Content>
                </Card.Root>
              )),
              getOrElse(() =>
                pipe(item, ({ sys, title }) => (
                  <Card.Root
                    key={sys.id}
                    className="flex h-[24rem] flex-col-reverse"
                    as="article"
                  >
                    <Card.Content className="relative flex h-full flex-col items-center justify-center gap-2 bg-purple-600">
                      <Typography
                        as="h2"
                        intent="title"
                        size="lg"
                        color="white"
                        className="text-center"
                      >
                        <DefaultLink
                          href={getLink(sys.id)?.path || ''}
                          full
                          color="inherit"
                        >
                          {title}
                        </DefaultLink>
                      </Typography>
                      <Box className="absolute top-0 right-0 rounded-bl-3xl bg-purple-900 py-2 px-4">
                        <Typography color="white" intent="title">
                          {t('club.guide')}
                        </Typography>
                      </Box>
                    </Card.Content>
                  </Card.Root>
                ))
              )
            )
          )
        ),
        toUndefined
      ),
    [getLink, t, articles]
  );
  return (
    <Box
      className="relative col-span-full mt-6 grid auto-rows-[1fr] grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3"
      data-mode="club"
    >
      {articleItems}
    </Box>
  );
};
