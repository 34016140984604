import { PriceList as PriceListType } from '@oresundsbron/api';
import { Box, Table, Typography } from '@oresundsbron/bridge-ui';
import { FC, useMemo } from 'react';
import { Currency } from '@oresundsbron/type-safeguards';
import { toCurrency } from '../../../../lib/currency';
import { pipe } from 'fp-ts/lib/function';
import { findFirst, head } from 'fp-ts/lib/Array';
import { chain, fromNullable, map, toUndefined } from 'fp-ts/lib/Option';
import { useTranslation } from 'next-i18next';
import { ContractName } from '../../../ContractName';
import { Fullprice } from '../../../../lib/vehicle';
import { Price, VariantWithClassID } from './utils';
import { useRouter } from 'next/router';

export const PriceListOresundGO: FC<
  Omit<PriceListType, 'variants'> & {
    prices: Price[];
    variants: VariantWithClassID[];
  }
> = ({ variants, prices }) => {
  const { locale } = useRouter();
  const { t } = useTranslation(['common']);
  const variant = useMemo(() => pipe(variants, head, toUndefined), [variants]);
  const price = useMemo(() => pipe(prices, head, toUndefined), [prices]);

  const fullPrice = useMemo(
    () =>
      pipe(
        price,
        fromNullable,
        map((x) => x.classId),
        chain((x) =>
          pipe(
            Fullprice,
            findFirst((p) => p.classId === x && p.currency === price?.currency)
          )
        ),
        toUndefined
      ),
    [price]
  );

  if (!price || !variant) {
    return null;
  }

  return (
    <Box>
      <Table.Root scroll="horizontal">
        <Box as="thead">
          <Table.Row>
            <Table.Heading></Table.Heading>
            <Table.Heading
              as="th"
              className="whitespace-nowrap pl-4 text-right first:pl-0"
            >
              <ContractName contract="oresundGo" ns="common" />
            </Table.Heading>
            <Table.Heading
              as="th"
              className="whitespace-nowrap pl-4 text-right first:pl-0"
            >
              {t('price.original')}
            </Table.Heading>
          </Table.Row>
        </Box>
        <Box as="tbody">
          <Table.Row className="border-b">
            <Table.Cell className="py-4">
              <Typography className="flex-[2] whitespace-nowrap sm:flex-[3]">
                {t('price.oneWay')}
              </Typography>
            </Table.Cell>
            <Table.Cell className="py-4">
              <Typography className="flex-[2] whitespace-nowrap text-right sm:flex-[3]">
                {toCurrency(
                  price.salesPriceInclVat,
                  locale,
                  price.currency as Currency
                )}
              </Typography>
            </Table.Cell>
            {fullPrice ? (
              <Table.Cell className="py-4">
                <Typography className="flex-[2] whitespace-nowrap text-right sm:flex-[3]">
                  {toCurrency(
                    fullPrice.priceInclVat,
                    locale,
                    fullPrice.currency as Currency
                  )}
                </Typography>
              </Table.Cell>
            ) : null}
          </Table.Row>
        </Box>
      </Table.Root>
      <Typography className="w-max-[60ch] mt-4" intent="label">
        {t('price.oresundgo.disclaimer')}
      </Typography>
    </Box>
  );
};
