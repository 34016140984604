import { pipe } from 'fp-ts/lib/function';
import { fromPredicate, map } from 'fp-ts/lib/Option';
import { BridgeStatus } from '../../hooks/useBridgeStatus';

export function isOpen<T>(onSome: () => T) {
  return (status: BridgeStatus['status']) =>
    pipe(
      status,
      fromPredicate((d) => d === 'open'),
      map(onSome)
    );
}

export function isClosed<T>(onSome: () => T) {
  return (status: BridgeStatus['status']) =>
    pipe(
      status,
      fromPredicate((d) => d === 'closed'),
      map(onSome)
    );
}

export function isWarning<T>(onSome: () => T) {
  return (status: BridgeStatus['status']) =>
    pipe(
      status,
      fromPredicate((d) => status === 'warning'),
      map(onSome)
    );
}

export function isUnknown<T>(onSome: () => T) {
  return (status: BridgeStatus['status']) =>
    pipe(
      status,
      fromPredicate((d) => status === 'unknown'),
      map(onSome)
    );
}
