import {
  ContentfulQueryKeys,
  useContentfulQuery,
  Reference,
} from '@oresundsbron/api';
import { findFirst, filterMap, head, filter } from 'fp-ts/lib/Array';
import { pipe, flow } from 'fp-ts/lib/function';
import {
  chain,
  fromNullable,
  toUndefined,
  map,
  filter as filterO,
  getOrElse,
} from 'fp-ts/lib/Option';
import { lookup, toEntries } from 'fp-ts/lib/Record';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { includes, split, Eq } from 'fp-ts/lib/string';
import { toArray } from 'fp-ts/lib/ReadonlyArray';

export function useLinks() {
  const { locale, isPreview } = useRouter();
  const { data } = useContentfulQuery(
    ContentfulQueryKeys.Links({ preview: isPreview, locales: [locale] }),
    (req) => req.Links.list({ preview: isPreview, locales: [locale || ''] })
  );

  const links = useMemo(
    () => pipe(data, fromNullable, chain(lookup(locale || '')), toUndefined),
    [data, locale]
  );

  const getLinkMaps = useCallback(
    (id: string) =>
      pipe(
        links,
        fromNullable,
        map(
          flow(
            toEntries,
            filter(([k]) => includes('_')(k)),
            filterMap(([ks, l]) =>
              pipe(
                ks,
                flow(split('_'), toArray, head),
                filterO((idk) => Eq.equals(idk, id)),
                map(() => l)
              )
            ),
            head,
            toUndefined
          )
        ),
        toUndefined
      ),
    [links]
  );

  const getLink = useCallback(
    (id: string) =>
      pipe(
        links,
        fromNullable,
        chain(lookup(id)),
        getOrElse(() => getLinkMaps(id))
      ),
    [links, getLinkMaps]
  );

  const findLink = useCallback(
    (path: string) =>
      pipe(
        links,
        fromNullable,
        map((a) => toEntries(a)),
        chain(findFirst(([, l]) => l.path === path)),
        map(([, l]) => l)
      ),
    [links]
  );

  const findPageId = useCallback(
    (path: string) =>
      pipe(
        links,
        fromNullable,
        map((a) => toEntries(a)),
        chain(findFirst(([, l]) => l.path === path)),
        map(([k]) => k)
      ),
    [links]
  );

  const findLinkByCodeRef = useCallback(
    (ref: Reference) =>
      pipe(
        links,
        fromNullable,
        map((a) => toEntries(a)),
        chain(findFirst(([, l]) => l.ref === ref)),
        map(([, l]) => l)
      ),
    [links]
  );

  return {
    links,
    getLink,
    findLink,
    findLinkByCodeRef,
    findPageId,
  };
}
