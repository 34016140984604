import { Grid, PageWrapper, Typography } from '@oresundsbron/bridge-ui';
import { DefaultLink } from './Links/DefaultLink';

export const PreviewBar = () => (
  <PageWrapper as="div" className="grid w-screen bg-neutral-200 py-4 ">
    <Grid className="col-start-2">
      <Typography
        intent="title"
        className="col-span-full sm:col-span-4 sm:col-start-3 sm:text-center md:col-start-5"
      >
        Du ser just nu en förhandsvisning av sidan.
      </Typography>
      <DefaultLink
        href="/api/exit-preview"
        className="col-span-full sm:col-span-2 sm:col-start-7 sm:text-right md:col-span-3 md:col-start-10"
      >
        Stäng förhandsvisningen
      </DefaultLink>
    </Grid>
  </PageWrapper>
);
