import { fromPredicate, map, getOrElse, some, chain, isNone, alt, fromNullable, of, isSome, none, sequenceArray, getOrElseW } from 'fp-ts/Option';
import { pipe, flow } from 'fp-ts/function';
import { has, deleteAt, toEntries, keys, lookup as lookup$1, upsertAt } from 'fp-ts/Record';
import { reduce as reduce$1, lookup, filter } from 'fp-ts/Array';
import { match, matchW } from 'fp-ts/boolean';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _iterableToArrayLimit(arr, i) {
  var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];

  if (_i == null) return;
  var _arr = [];
  var _n = true;
  var _d = false;

  var _s, _e;

  try {
    for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i];

  return arr2;
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}

function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}

var isNumericString = function isNumericString(x) {
  return typeof x === 'string' && /^-?\d+$/.test(x);
};
var deepLookup = function deepLookup(record) {
  return function (path) {
    return pipe((path || '').split('.'), function (keys) {
      var curr = some(record);

      for (var i = 0; i < keys.length; i++) {
        var next = void 0;

        if (isNumericString(keys[i])) {
          next = chain(lookup(parseInt(keys[i])))(curr);
        }

        next = chain(lookup$1(keys[i]))(curr);

        if (isNone(next)) {
          return next;
        }

        curr = next;
      }

      return curr;
    });
  };
};
var isRecord = function isRecord(x) {
  return _typeof(x) === 'object';
};
var isString = function isString(x) {
  return typeof x === 'string';
};
var isPath = function isPath(x) {
  return isString(x) && x.indexOf('.') > -1;
};
var mergeRecords = function mergeRecords(x) {
  return function (y) {
    return pipe(x, toEntries, reduce(y, function (p, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          k = _ref2[0],
          v = _ref2[1];

      return _objectSpread2(_objectSpread2({}, p), {}, _defineProperty({}, k, v));
    }));
  };
};
var lazyEmptyCssRecord = function lazyEmptyCssRecord() {
  return {};
};
var excludeProp = function excludeProp(key) {
  return function (props) {
    return pipe(props, fromPredicate(function (p) {
      return has(key, p);
    }), map(deleteAt(key)), getOrElse(function () {
      return props;
    }));
  };
};
var excludeProps = function excludeProps(propsToExclude) {
  return function (props) {
    return pipe(propsToExclude, reduce$1(props, function (res, key) {
      return excludeProp(key)(res);
    }));
  };
};
var includeProps = function includeProps(propsToInclude) {
  return function (props) {
    return pipe(props, keys, reduce({}, function (p, k) {
      return pipe(k, fromPredicate(includes(propsToInclude)), map(function () {
        return _objectSpread2(_objectSpread2({}, p), {}, _defineProperty({}, k, props[k]));
      }), getOrElse(function () {
        return p;
      }));
    }));
  };
};
var includes = function includes(arr) {
  return function (el) {
    return arr.includes(el);
  };
};
var reduce = function reduce(initial, fn) {
  return function (arr) {
    return arr.reduce(fn, initial);
  };
};

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};
  var target = _objectWithoutPropertiesLoose(source, excluded);
  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr);
}

function _iterableToArray(iter) {
  if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}

var isResponsiveValue = function isResponsiveValue(x) {
  return _typeof(x) === 'object' && Object.keys(x).some(function (k) {
    return k === 'xs';
  });
};

var getValue = function getValue(key, x) {
  if (isResponsiveValue(x)) {
    return x[key];
  }

  return x;
};

var toMediaQueries = function toMediaQueries(key, _ref, curr) {
  var xs = _ref.xs,
      sm = _ref.sm,
      md = _ref.md;
  return function (tokens) {
    var result = _objectSpread2(_objectSpread2({}, curr), {}, _defineProperty({}, key, getValue('xs', xs)));

    if (sm) {
      var n = tokens.breakpoints['sm'];
      var k = "@media screen and (min-width: ".concat(n, ")");
      result = _objectSpread2(_objectSpread2({}, result), {}, _defineProperty({}, k, _objectSpread2(_objectSpread2({}, result[k] || {}), {}, _defineProperty({}, key, getValue('sm', sm)))));
    }

    if (md) {
      var _n = tokens.breakpoints['md'];

      var _k = "@media screen and (min-width: ".concat(_n, ")");

      result = _objectSpread2(_objectSpread2({}, result), {}, _defineProperty({}, _k, _objectSpread2(_objectSpread2({}, result[_k] || {}), {}, _defineProperty({}, key, getValue('md', md)))));
    }

    return result;
  };
};

var transformResponsiveValues = function transformResponsiveValues(tokens) {
  return function (obj) {
    return pipe(obj, toEntries, reduce$1(obj, function (curr, _ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
          key = _ref3[0],
          val = _ref3[1];

      return pipe(val, fromPredicate(isResponsiveValue), map(function (v) {
        return _objectSpread2(_objectSpread2({}, curr), toMediaQueries(key, v, curr)(tokens));
      }), alt(function () {
        return pipe(val, fromPredicate(isRecord), map(function (v) {
          return transformResponsiveValues(tokens)(v);
        }), map(function (v) {
          return _objectSpread2(_objectSpread2({}, curr), {}, _defineProperty({}, key, v));
        }));
      }), getOrElse(function () {
        return curr;
      }));
    }));
  };
};

var pathsCache = new Set([]);
var getPaths = function getPaths(val) {
  var arr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return pipe(val, fromPredicate(function (v) {
    return isRecord(v);
  }), map(function (v) {
    return pipe(v, toEntries, reduce$1(arr, function (res, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          o = _ref2[1];

      return pipe(isRecord(o) && !isResponsiveValue(o), match(function () {
        return [].concat(_toConsumableArray(res), [key]);
      }, function () {
        return [].concat(_toConsumableArray(res), _toConsumableArray(getPaths(o).map(function (s) {
          return "".concat(key, ".").concat(s);
        })));
      }));
    }));
  }), getOrElse(function () {
    return arr;
  }));
};
var lookupToken = function lookupToken(tokens) {
  return function (path) {
    if (pathsCache.size === 0) {
      pathsCache = new Set(getPaths(tokens));
    }

    var str = "".concat(path);
    var pathToUse = '';
    pathsCache.forEach(function (v) {
      if (str.indexOf(v) > -1) {
        pathToUse = v;
        str = str.replaceAll(v, '#');
      }
    });

    if (str === '#') {
      return pipe(pathToUse, fromPredicate(isPath), chain(deepLookup(tokens)));
    }

    return pipe(pathToUse, fromPredicate(isPath), chain(deepLookup(tokens)), map(function (value) {
      return pipe(value, isResponsiveValue, matchW(function () {
        return str.replaceAll('#', "".concat(value));
      }, function () {
        return Object.entries(value).reduce(function (res, _ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
              key = _ref4[0],
              val = _ref4[1];

          return _objectSpread2(_objectSpread2({}, res), {}, _defineProperty({}, key, str.replaceAll('#', "".concat(val))));
        }, {});
      }));
    }));
  };
};
var transformTokens = function transformTokens(tokens) {
  return function (obj) {
    return pipe(obj, fromPredicate(function (x) {
      return isRecord(x);
    }), map(toEntries), map(reduce$1(obj, function (p, _ref5) {
      var _ref6 = _slicedToArray(_ref5, 2),
          key = _ref6[0],
          val = _ref6[1];

      return pipe(val, lookupToken(tokens), map(function (v) {
        return _objectSpread2(_objectSpread2({}, p), {}, _defineProperty({}, key, v));
      }), alt(function () {
        return pipe(val, transformTokens(tokens), map(function (v) {
          return _objectSpread2(_objectSpread2({}, p), {}, _defineProperty({}, key, v));
        }));
      }), getOrElse(function () {
        return p;
      }));
    })));
  };
};

var parseProps = function parseProps(tokens) {
  return function (prop) {
    return pipe(prop, fromPredicate(function (props) {
      return typeof props === 'function';
    }), map(function (p) {
      return p(tokens);
    }), alt(function () {
      return of(prop);
    }));
  };
};

var fromProps = function fromProps(tokens) {
  return function (prop) {
    return pipe(fromNullable(prop), chain(parseProps(tokens)));
  };
};

var EMPTY_COMPONENT = {};
var SOME_EMPTY_COMPONENT = some(EMPTY_COMPONENT);

var isComponentVariant = function isComponentVariant(x) {
  return !!Object.keys(x).length && Object.entries(x).some(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2);
        _ref2[0];
        var val = _ref2[1];

    return _typeof(val) === 'object' && !isResponsiveValue(val);
  });
};

var lookupRootIfNotVariant = function lookupRootIfNotVariant(val) {
  return pipe(val, fromPredicate(isComponentVariant), chain(flow(lookup$1(':root'), alt(function () {
    return SOME_EMPTY_COMPONENT;
  }))), getOrElse(function () {
    return val;
  }));
};

var lookupVariants = function lookupVariants(_ref3) {
  var _ref4 = _slicedToArray(_ref3, 2),
      namespace = _ref4[0],
      variantPath = _ref4[1];

  return function (components) {
    var componentRoot = pipe(namespace, deepLookup(components), map(lookupRootIfNotVariant));
    var componentVariant = pipe(variantPath, deepLookup(components));
    return [componentRoot, componentVariant];
  };
};

var merge = function merge(prev, curr) {
  return Object.entries(curr).reduce(function (res, _ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
        key = _ref6[0],
        val = _ref6[1];

    var pVal = prev[key];

    if (val && pVal && isRecord(val) && isRecord(pVal)) {
      return _objectSpread2(_objectSpread2({}, res), {}, _defineProperty({}, key, merge(pVal, val)));
    }

    return _objectSpread2(_objectSpread2({}, res), {}, _defineProperty({}, key, val));
  }, prev);
};

var mergeVariants = function mergeVariants(variants) {
  return pipe(variants, filter(isSome), function (variants) {
    return variants.length === 0 ? [none] : variants;
  }, sequenceArray, map(reduce(EMPTY_COMPONENT, function (prev, curr) {
    return merge(prev, curr);
  })));
};

var lookupComponent = function lookupComponent(component, variant) {
  return function (components) {
    return pipe(components, lookupVariants(!variant ? [String(component)] : [String(component), "".concat(String(component), ".").concat(String(variant))]), mergeVariants);
  };
};

var systemProps = ['m', 'mt', 'mr', 'mb', 'ml', 'mx', 'my', 'p', 'pt', 'pr', 'pb', 'pl', 'px', 'py'];
var translator = {
  m: 'margin',
  mt: 'marginBlockStart',
  mb: 'marginBlockEnd',
  ml: 'marginInlineStart',
  mr: 'marginInlineEnd',
  mx: 'marginInline',
  my: 'marginBlock',
  p: 'padding',
  pt: 'paddingBlockStart',
  pb: 'paddingBlockEnd',
  pl: 'paddingInlineStart',
  pr: 'paddingInlineEnd',
  px: 'paddingInline',
  py: 'paddingBlock'
};
var excludeSystemProps = excludeProps(systemProps);
var extractSystemProps = includeProps(systemProps);

var getProps = function getProps(tokens) {
  return function (props) {
    return pipe(props, fromProps(tokens), getOrElse(function () {
      return props;
    }));
  };
};

var transformSystemProps = function transformSystemProps(props) {
  return pipe(toEntries(props), reduce(props, function (res, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];

    return pipe(translator, lookup$1(key), map(function (transformedKey) {
      return pipe(res, upsertAt(transformedKey, value), deleteAt(key));
    }), alt(function () {
      return pipe(value, fromPredicate(function (v) {
        return isRecord(v) && !isResponsiveValue(v);
      }), map(function (v) {
        return transformSystemProps(v);
      }), map(function (v) {
        return pipe(res, upsertAt(key, v));
      }));
    }), getOrElse(function () {
      return res;
    }));
  }));
};
var fromSystemProps = function fromSystemProps(tokens) {
  return function (props) {
    return pipe(props, extractSystemProps, function (p) {
      return toEntries(p);
    }, reduce({}, function (res, _ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
          key = _ref4[0],
          value = _ref4[1];

      return pipe(res, upsertAt(key, pipe(value, getProps(tokens))));
    }), transformSystemProps);
  };
};

var fromSxProp = fromProps;

var _excluded = ["component", "variant", "sx"];
var cssProps = ['component', 'variant', 'sx'];
var excludeCssProps = excludeProps(cssProps);
var css = function css(_ref) {
  var component = _ref.component,
      variant = _ref.variant,
      sx = _ref.sx,
      rest = _objectWithoutProperties(_ref, _excluded);

  return function (theme) {
    var compCSS = pipe(fromNullable(component), chain(function (comp) {
      return lookupComponent(comp, variant)(theme.components);
    }), map(transformSystemProps), getOrElseW(lazyEmptyCssRecord));
    var systemPropsCss = fromSystemProps(theme.tokens)(rest);
    var sxCSS = pipe(sx, fromSxProp(theme.tokens), map(transformSystemProps), getOrElseW(lazyEmptyCssRecord));
    return pipe(compCSS, mergeRecords(systemPropsCss), mergeRecords(sxCSS), transformTokens(theme.tokens), map(transformResponsiveValues(theme.tokens)), getOrElseW(lazyEmptyCssRecord), function (c) {
      return Object.keys(c).length > 0 ? c : undefined;
    });
  };
};

var stylingProps = ['sx'];
var stylingPropsWithCss = [].concat(stylingProps, ['css']);

var extendCss = function extendCss(props) {
  return _objectSpread2(_objectSpread2({}, props), {}, {
    css: function css$1(theme) {
      var styles = css({
        sx: props.sx
      })(theme);

      var raw = typeof props.css === 'function' ? props.css(theme) : props.css;
      return [styles, raw].filter(Boolean);
    }
  });
};

var extendAndExclude = function extendAndExclude(props) {
  return pipe(props, extendCss, excludeProps(stylingProps));
};

var hasStylingProps = function hasStylingProps(props) {
  return !!props && stylingPropsWithCss.some(function (propKey) {
    return !!props[propKey];
  });
};

var parseStylingProps = function parseStylingProps(props) {
  return pipe(props, fromPredicate(hasStylingProps), map(extendAndExclude), getOrElse(function () {
    return props;
  }));
};

export { extractSystemProps as a, excludeCssProps as b, css as c, excludeProp as d, excludeSystemProps as e, excludeProps as f, parseStylingProps as p };
