import { Box, Grid, PageWrapper, Typography } from '@oresundsbron/bridge-ui';
import { Person } from '@oresundsbron/icons';
import { filter, head, map } from 'fp-ts/lib/Array';
import { map as mapO, toUndefined, getOrElse } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { Logo } from '../components/Logo';
import { useLinks } from '../hooks/useLinks';
import { useNavigation } from '../hooks/useNavigation';
import { isNavigationLink } from '../lib/navigation';
import { LanguagePicker } from './LanguagePicker';
import { MainNavigation } from './MainNavigation';
import { lookup } from 'fp-ts/lib/Record';
import { Eq } from 'fp-ts/lib/string';
import { getIsOresundPay } from '~/stores/auth';
import { match as matchB } from 'fp-ts/lib/boolean';
import { DefaultLink } from '~/components/Links/DefaultLink';

export const OSBNavBar = () => {
  const { t } = useTranslation(['common'], { nsMode: 'fallback' });
  const { getLink } = useLinks();

  const nav = useNavigation('Header');
  const router = useRouter();
  const { query, pathname } = router;
  const isOresundPaySession = getIsOresundPay();
  const colorMode = useMemo(
    () => (pathname.includes('club') ? 'club' : ''),
    [pathname]
  );
  const isAccountPage = useMemo(() => pathname.includes('account'), [pathname]);

  const [logoSrc, setLogoSrc] = useState<string>('/oresundsbron-logo.svg');

  const links = useMemo(
    () =>
      pipe(
        nav?.sections || [],
        filter(isNavigationLink),
        map(({ title, pageLink }) => ({
          title,
          link: getLink(pageLink.sys.id),
        })),
        filter(({ link }) => !!link)
      ),
    [nav?.sections, getLink]
  );

  let isOresundPay = useMemo(
    () =>
      pipe(
        isOresundPaySession,
        matchB(
          () =>
            pipe(
              query,
              lookup('source'),
              mapO(
                (s) =>
                  !!Eq.equals(s as unknown as string, 'pay') && isAccountPage
              ),
              getOrElse(() => false)
            ),
          () => isAccountPage
        )
      ),
    [query, isOresundPaySession, isAccountPage]
  );

  useEffect(
    () =>
      setLogoSrc(isOresundPay ? '/ospay-logo.svg' : '/oresundsbron-logo.svg'),
    [isOresundPay]
  );

  return (
    <PageWrapper as="header" data-mode={colorMode}>
      <Grid
        as="nav"
        id="header-nav"
        className="col-start-2 items-center"
        aria-label={nav?.title}
      >
        <Box className="col-span-2 row-start-1 py-3 md:col-span-3">
          {pipe(
            links,
            head,
            mapO(({ title, link }) => (
              <DefaultLink href={link?.path || ''} aria-label={title}>
                <Logo logoSrc={logoSrc} alt="Øresundsbron logo" />
              </DefaultLink>
            )),
            toUndefined
          )}
        </Box>
        <Box
          as="ul"
          className="col-span-full row-start-2 flex  justify-between gap-6 sm:col-span-3 sm:col-start-4 sm:row-start-1 sm:justify-end md:col-start-7"
        >
          {pipe(
            links,
            map(({ title, link }) => (
              <Box key={link?.path} as="li" className="relative py-3">
                <DefaultLink
                  href={link?.path || ''}
                  full
                  className={`uppercase ${
                    router.asPath.startsWith(link?.path || '')
                      ? '!text-black underline'
                      : '!text-black no-underline hover:!text-black'
                  }`}
                >
                  {title}
                </DefaultLink>
              </Box>
            ))
          )}
        </Box>
        <Box
          as="ul"
          className="col-span-2 col-start-3 row-start-1 flex justify-end gap-4 py-3 sm:col-start-7 sm:gap-6 md:col-span-3 md:col-start-10"
        >
          <Box as="li">
            <LanguagePicker />
          </Box>
          <Box as="li" className="md:col-start flex items-center">
            <DefaultLink
              href="/account/dashboard"
              color="inherit"
              className="flex items-center gap-2 hover:!text-black"
              underline={false}
            >
              <Typography
                as="span"
                className="rounded-full bg-neutral-200 p-1.5 text-2xl"
              >
                <Person aria-label={t('action.myAccount')} />
              </Typography>
              <Typography as="span" className="hidden md:block">
                {t('action.myAccount')}
              </Typography>
            </DefaultLink>
          </Box>
          <Box as="li">
            <MainNavigation />
          </Box>
        </Box>
      </Grid>
    </PageWrapper>
  );
};
