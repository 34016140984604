export default function contentfulLoader({ src = '', quality, width }) {
  if (src[0] === '/') {
    return src;
  }
  const url = new URL(
    src.replace('downloads.ctfassets.net', 'images.ctfassets.net')
  );
  url.searchParams.set('fm', 'webp');
  url.searchParams.set('w', width.toString());
  url.searchParams.set('q', quality ? quality.toString() : '75');
  return url.href;
}
